<template>
  <div class="thePhones">
    <div class="left-phone">
      <img src="../assets/images/header/app-left.png" alt="Phone Left" />
    </div>
    <div class="middle-phone">
      <div id="deviceFrame"></div>
      <div id="video">
        <video
          width="100%"
          height="100%"
          controls
          poster="../assets/video/poster.jpg"
          playsinline
          controlsList="nodownload"
        >
          >
          <source src="../assets/video/mr-right-trailer.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
    <div class="right-phone">
      <img src="../assets/images/header/app-right.png" alt="Phone Right" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ThePhones",
};
</script>

<style scoped>
#mobileNav {
  display: none;
}

.thePhones {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 50px;
}

.middle-phone {
  background: #000;
  position: relative;
  width: 356px;
  max-width: 356px;
  z-index: 1;
  overflow: hidden;
  border-radius: 41px;
  border: 5px solid #000;
  box-shadow: 15px 15px 0px rgba(0, 0, 0, 1);
}

#vid {
  border-radius: 41px;
  background: #000;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

#deviceFrame {
  background: url("../assets/images/header/phone-frame.png") no-repeat;
  background-size: 100% 100%;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  pointer-events: none;
}
.left-phone {
  margin-top: 0px;
  margin-right: -70px;
  height: 520px;
}
.left-phone img {
  height: 100%;
}

.right-phone {
  margin-top: 0px;
  margin-left: -48px;
  height: 520px;
}

.right-phone img {
  height: 100%;
}
@media screen and (max-width: 1000px) {
  .thePhones {
    max-width: 98%;
    margin-top: 0px;
  }
  .middle-phone {
    width: 100%;
    margin-left: -5px;
    border-radius: 40px;
    transform: scale(0.8);
  }
  .left-phone {
    display: none;
  }
  .right-phone {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  .thePhones {
    max-width: 98%;
  }
  .middle-phone img {
    width: 90%;
  }
  .left-phone {
    display: none;
  }
  .right-phone {
    display: none;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .thePhones {
    max-width: 98%;
  }
  .middle-phone img {
    width: 90%;
  }
  .left-phone {
    display: none;
  }
  .right-phone {
    display: none;
  }
}
</style>
