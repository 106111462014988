<template>
  <div>
    <div v-if="!isMobile" class="button">
      <div class="button-left">
        <div class="text-one">Download app</div>
        <div class="text-two">Download app</div>
      </div>
      <div class="button-right"><button-arrow></button-arrow></div>
    </div>
    <div class="button" v-else>
      <div class="button-left" @click="trackDownload">
        <div class="text-one">Download App</div>
        <div class="text-two">Download App</div>
      </div>
      <div class="button-right"><button-arrow></button-arrow></div>
    </div>
  </div>
</template>

<script>
import ButtonArrow from "../svg/ButtonArrow.vue";
export default {
  name: "NavButton",
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ButtonArrow,
  },
  methods: {
    trackDownload() {
      /*global gtag*/
      gtag("event", "click", { label: "download app" });
      setTimeout(() => {
        document.location = "/download?type=click";
      }, 200);
    },
  },
};
</script>

<style scoped>
.button {
  border-radius: 8px;
  font-size: 18px;
  display: flex;
  height: 42px;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  overflow: hidden;
}

.button:hover {
  animation: mouseHover 0.3s linear forwards;
}

@keyframes mouseHover {
  0% {
    cursor: url("../../assets/images/ui/cursor-01.png") 50 50, auto;
  }
  8% {
    cursor: url("../../assets/images/ui/cursor-02.png") 50 50, auto;
  }
  16% {
    cursor: url("../../assets/images/ui/cursor-03.png") 50 50, auto;
  }
  24% {
    cursor: url("../../assets/images/ui/cursor-04.png") 50 50, auto;
  }
  33% {
    cursor: url("../../assets/images/ui/cursor-05.png") 50 50, auto;
  }
  41% {
    cursor: url("../../assets/images/ui/cursor-06.png") 50 50, auto;
  }
  48% {
    cursor: url("../../assets/images/ui/cursor-07.png") 50 50, auto;
  }
  56% {
    cursor: url("../../assets/images/ui/cursor-08.png") 50 50, auto;
  }
  67% {
    cursor: url("../../assets/images/ui/cursor-09.png") 50 50, auto;
  }
  77% {
    cursor: url("../../assets/images/ui/cursor-10.png") 50 50, auto;
  }
  85% {
    cursor: url("../../assets/images/ui/cursor-11.png") 50 50, auto;
  }
  100% {
    cursor: url("../../assets/images/ui/cursor-12.png") 50 50, auto;
  }
}

.button-left {
  cursor: inherit;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  background: #fff;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border-right: 1px solid #000;
  position: relative;
  width: 150px;
}
.button-left div {
  cursor: inherit;
  letter-spacing: -0.02em;
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.button-left div.text-two {
  opacity: 0;
  top: 60px;
  transition: all 0.25s ease-in-out;
}
.button-right {
  cursor: inherit;
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  background: #bdf771;
  border-left: 1px solid #000;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: all 0.25s ease-in-out;
}

.button:hover .button-right {
  padding-left: 10px;
}
.button:hover .button-left div.text-one {
  margin-top: -60px;
  opacity: 0;
}
.button:hover .button-left div.text-two {
  margin-top: -48px;
  opacity: 1;
}

@media screen and (max-width: 320px) {
  .button {
    transform: scale(0.8);
  }
}

@media screen and (max-width: 900px) {
  .button {
    transition: none;
  }
  .text-two {
    display: none;
  }
  .button-left div {
    transition: none;
  }
  .button-left div.text-two {
    transition: none;
  }
  .button-right {
    transition: none;
  }
  .button:hover {
    transition: none;
    cursor: url("../../assets/images/ui/mousehover.png") auto;
  }
  .button:hover .button-right,
  .button:active .button-right {
    padding-left: 0px;
  }
  .button:hover .button-left div.text-one {
    margin-top: 0px;
    opacity: 1;
  }
  .button:hover .button-left div.text-two {
    margin-top: 0px;
    opacity: 1;
  }
}
</style>
