<template>
  <div id="outerContainer">
    <!-- MODAL -->
    <transition name="modl" v-if="modalVisible">
      <div class="modal">
        <modal-download @close-modal="hideModal"></modal-download></div
    ></transition>
    <div id="nav">
      <the-nav :logoVisible="true" @open-modal="showModal"></the-nav>
    </div>
    <div id="app">
      <div class="container">
        <a href="/"
          ><div class="back-button"><back-button></back-button></div
        ></a>
        <section-headline
          headLine="Terms of Use"
          className="terms"
        ></section-headline>
        <div class="content-section">
          <h1>Mr. Right Application Terms of Use</h1>
          <h2>Updated as of December 1, 2022 (the “Effective Date”)</h2>
          <p>
            The Mr. Right app ("<span class="bold">App</span>") features an
            interactive show where you may choose your own adventure. Your
            choices shape the personality of the character Rob, a new humanlike
            robot, and determine whether Sophia will find her Mr. Right in this
            romantic comedy. You may browse photos and media, and view and
            capture media in augmented reality. Before using the App, carefully
            read these Terms of Use ("<span class="bold">Agreement</span>"),
            which is a legal agreement between you, on the one hand, and Nametag
            Production, Inc. and/or its designee(s) ("<span class="bold"
              >Company</span
            >", "<span class="bold">we</span>", "<span class="bold">us</span>",
            or "<span class="bold">our</span>"), on the other hand. If you are
            accessing or using the App on behalf of a company or organization,
            you represent that you have permission and authority to represent
            such company or organization, and such company or organization will
            also be considered a party to the contract established by this
            Agreement. This Agreement sets forth the terms and conditions under
            which you agree to use and we agree to provide the App. By accessing
            or using the App or participating in or using any service offered on
            the App, you represent that you are at least 18 years of age and
            that you understand and accept this Agreement.
            <span class="bold"
              >If you do not agree to this Agreement, then do not use the App
              and uninstall it from your device.</span
            >
            <br /><br />
            From time to time, Company may decide to modify, add, or delete
            portions of this Agreement and will post those changes here. If
            Company does so, your continued access to or use of the App after
            any such changes have been made means you agree to such changes. We
            will call your attention to any material modifications to this
            Agreement, either directly by email or with a notification (whether
            in the form of a push notification, an initial notification when you
            open the App or another form). The amended Agreement will be
            effective automatically when posted or on a date certain, as
            indicated. In addition, Company reserves the right to change and
            improve the features and functionality of the App at any time, which
            includes adding, modifying or removing features and functionality of
            the App or updating how services on the App are provided.
          </p>
          <ul>
            <li>
              <span class="bold"
                >Safe and Appropriate Use; Prohibited Actions.</span
              >
              <br /><br />
              We want you to have a fun and safe experience using the App, so
              please remember to be a good person, stay safe and aware of your
              surroundings, do not overshare personal information that could
              compromise your identity, respect others, never engage in violence
              against yourself or others or any illegal activities, and obtain
              permission before using or sharing someone else's content. Company
              reserves the right to refuse service, cancel service orders,
              terminate access to the App or its services, or remove or edit
              features or content on the App, in its sole discretion.<br /><br />
              By using the App:
              <ul>
                <li>
                  You agree, represent, and warrant that you will provide us
                  with true, current, complete, and accurate information in
                  connection with your use of the App or participation in or use
                  of any service offered on the App, and you will update or
                  notify us of any changes to such information to keep it true,
                  current, complete, and accurate.
                </li>
                <li>
                  You agree to notify us immediately of any unauthorized access
                  to or use of the App or its services of which you become
                  aware.
                </li>
                <li>
                  You are not permitted to upload material onto the App or its
                  services that you should know or do know infringes on the
                  intellectual property rights of others.
                </li>
                <li>
                  You may not share or post information or other content that is
                  false, misleading, or violates the rights of another, and you
                  may not pass the content of others as your own.
                </li>
                <li>
                  You may not upload material that places unnecessary load so as
                  to affect the performance of the App or Company’s systems or
                  equipment. You may not use the App or its services in a manner
                  that could block access to, impair, damage or otherwise
                  disable Company or any of its systems, servers or equipment.
                </li>
                <li>
                  You agree not to access the App by any automated means,
                  including, but not limited to, by scraping or other methods of
                  gathering information or data.
                </li>
                <li>
                  You may not attempt to gain unauthorized access to the App or
                  its services or to any other user’s account(s), computer
                  systems or networks through password mining, keystroke
                  logging, hacking or any other means.
                </li>
                <li>
                  You will not upload any files that contain viruses, Trojan
                  horses, malware, spyware, worms, corrupted files, or any other
                  material or software that may damage the operation of another
                  device. Any and all materials that may be uploaded are subject
                  to applicable federal, state, and international laws.
                </li>
              </ul>
            </li>
            <!--  -->
            <li>
              <span class="bold">Security.</span> <br /><br />
              Violating the security of the App or its services is prohibited
              and may result in criminal and civil liability, along with
              immediate termination of your access to the App and its services.
              We may investigate incidents involving such violations and may
              involve, and will cooperate with, law enforcement. Examples of
              security violations include, without limitation, disclosing or
              sharing of content beyond the permissions you have received,
              sharing or recklessly using any security credentials, usernames or
              passwords, unauthorized access to or use of data or systems
              including any attempt to probe, scan or test the vulnerability of
              the App or its services or to breach security or authentication
              measures, unauthorized monitoring of data or traffic, interference
              with service to any users, host or network including, without
              limitation, mail bombing, news bombing, other flooding techniques,
              deliberate attempts to overload a system, forging any TCP-IP
              packet header, e-mail header or any part of a message header,
              except for the authorized use of aliases or anonymous remailers,
              and using manual or electronic means to avoid any use limitations.
            </li>
            <!--  -->
            <li>
              <span class="bold">Risk of Loss.</span> <br /><br />
              We may, in our sole discretion, terminate your access to the App
              or use of its services at any time and for any or no reason.
              Company will not be liable to you or any third party for any
              termination of your access to the App or its services. If your
              status as an authorized user of the App or any of its services is
              terminated for any reason, whether voluntarily or involuntarily,
              you hereby represent, warrant and agree, as a condition for your
              use of the App and/or its services, that you will (a) stop using
              the App and its services and any information obtained therefrom,
              and (b) destroy all copies of any information obtained from the
              App or its services, including but not limited to, all content in
              your possession that originated with the App or its services.
            </li>
            <!--  -->
            <li>
              <span class="bold"
                >Verizon Customer Agreement; Third-Party Beneficiaries.
              </span>
              <br /><br />
              <ul>
                <li>
                  If you are a wireless customer of Cellco Partnership d/b/a
                  Verizon Wireless (“<span class="bold">Verizon</span>”), then
                  your use of the App is also subject to all of the terms in
                  Verizon’s
                  <a
                    href=" https://www.verizon.com/about/terms-conditions/overview"
                    >Customer Agreement</a
                  >, which you agreed to when you signed up to be Verizon’s
                  subscriber for wireless service.
                </li>
                <li>
                  You understand and agree that each of Verizon and its
                  subsidiaries, affiliates, entities and business ventures is an
                  express, intended third-party beneficiary of this Agreement
                  with full standing to enforce each, any and all of its
                  provisions as if it were an express party hereto (and that,
                  without limiting the foregoing, all references herein to
                  damages, injuries or other losses to Company, shall also
                  include the same as applicable to Verizon and its
                  subsidiaries, affiliates, entities and business ventures);
                  however, the foregoing shall not confer or impose any
                  obligation or liability upon Verizon or its subsidiaries,
                  affiliates, entities or business ventures hereunder or
                  otherwise in connection with the App.
                </li>
              </ul>
            </li>
            <!--  -->
            <li>
              <span class="bold">Privacy and Location Data</span> <br /><br />
              At Company, we are committed to privacy and security, and to
              providing the best possible user experience for our users.
              Accepting this Agreement means that you also agree to our
              <a href="/privacy-policy">Privacy Policy</a>, which is
              incorporated into this Agreement. Our Privacy Policy may be
              updated from time to time and describes the information we
              collect, how that information may be used and shared, and the
              choices you have about certain uses of information. The App may
              (a) require access to your device’s camera and gallery, (b) use
              your device’s location to add location information in your picture
              and video metadata, and to provide the most relevant and
              up-to-date content and experiences, and (c) collect your device’s
              location information while in use and you can revoke your location
              permission at any time in your device settings. You understand and
              agree that through your download and use of the App, you consent
              to the collection and use of your information consistent with the
              Privacy Policy. Our use of your information is always governed by
              our Privacy Policy. Our Privacy Policy may provide additional
              options to opt out of the sharing of this information depending on
              the jurisdiction in which you reside.
              <span class="bold"
                >If you do not want us to continue collecting your information
                through or in connection with the App, please delete the App
                from your device</span
              >.
            </li>
            <!--  -->
            <li>
              <span class="bold">Eligibility and Limitations. </span>
              <br /><br />
              The App may not work on all devices, operating systems, or
              networks. Some features, functionality, and content playback
              quality or usability may depend on your internet connection.
              Performance may vary depending upon network and coverage
              conditions, and there may be content optimized for
              <span class="bold"
                >5G Ultra Wideband. 5G Ultra Wideband is currently available
                only in parts of select cities</span
              >.
            </li>
            <!--  -->
            <li>
              <span class="bold">Charges. </span>
              <br /><br />
              The App is provided free of charge;
              <span class="bold"
                >however, data usage in the App may be large and data charges
                may apply when you download and use the App over cellular
                data</span
              >. You are responsible for all wireless data or service charges in
              connection with the downloading and use of the App. When using the
              App outside of the U.S., global data charges may apply.
            </li>
            <!--  -->
            <li>
              <span class="bold">Photosensitivity Notice. </span>
              <br /><br />
              Some scenes in some content, videos or augmented reality (AR)
              experiences, if any, may have a flashing effect or flashing lights
              or patterns that may trigger seizures or other symptoms for
              photosensitive viewers. Viewer and user discretion is advised.
            </li>
            <!--  -->
            <li>
              <span class="bold">Physical Activity Notice. </span>
              <br /><br />
              The App may include features that promote physical activity, and
              activities promoted by the App may pose risks, even if you are
              currently in good health. Consider the risks involved and consult
              with a licensed medical professional before engaging in any
              physical activity. We are not responsible or liable for any
              injuries or damages you may sustain that result from your use of,
              or inability to use, any of those features of the App. Your use of
              the App is at your own risk.
            </li>
            <!--  -->
            <li>
              <span class="bold">Ownership, Licenses, and Restrictions. </span>
              <br /><br />
              The App and the content contained therein are the property of
              Company and/or its licensors. The App and all content, materials,
              and software incorporated therein are protected by copyrights,
              patents, trademarks, trade secrets, and other intellectual
              property laws and proprietary rights. You are granted a limited
              personal, noncommercial, nonexclusive, non-assignable,
              non-sublicensable, and revocable license to access and use the App
              for its intended use, subject to the terms of this Agreement. You
              may not sell, resell, reproduce, distribute, display, perform,
              record, sublicense, loan, lease, otherwise transfer, alter,
              modify, merge, adapt, copy, translate, publish, export, create
              derivative works of, make any commercial use of, reverse engineer,
              decompile, attempt to derive the source code of, or disassemble
              the App or any software that is part of the App, attempt to
              disable or circumvent any digital rights management mechanism or
              other content protection measures, or otherwise use and exploit
              any part of the App. All rights not expressly granted to you
              herein are reserved. Your use of the App must comply with all
              applicable laws, rules and regulations. We may revoke this license
              at any time without notice.
              <br /><br />Source code for parts of the App software may be
              available for use, modification, and distribution under certain
              open source licenses. Certain App software or technical
              information may be licensed from third parties, and may be covered
              by one or more U.S. Patents, pending U.S. patent applications, and
              pending counterpart European and international patents.
            </li>
            <!--  -->
            <li>
              <span class="bold">User Content. </span>
              <br /><br />
              The App may allow you to take, create, edit, enhance, or share
              photos and/or other content (collectively, “<span class="bold"
                >User Content</span
              >”). Your use of the App and any User Content must comply with all
              applicable laws, rules and regulations, and the terms of use of
              any social media platform that you share and publicize User
              Content with. You are solely responsible for User Content you
              create, share and publicize, and the consequences of your
              creating, sharing and publicizing such User Content. You may not
              create, share or publicize User Content that is violent against
              yourself or others, defamatory, harassing, threatening, bigoted,
              hateful, vulgar, obscene, gore, sexual, nudity, pornographic, or
              otherwise offensive, that promotes illegal activity or that harms
              or can reasonably be expected to harm you or any person or entity,
              whether or not such material is protected by law. We reserve the
              right to remove any such User Content without advance notice. User
              Content may include copyrighted or trademarked material and may be
              subject to copyright or trademark protection, which limits or
              prohibits copying, transmission and/or use. You may not share User
              Content in a way that infringes any copyright or trademark.
              <span class="bold"
                >We do not actively monitor or control the User Content you
                choose to create, share or publicize from or through the App,
                and, to the fullest extent permitted by applicable law, we
                disclaim all responsibility for such User Content and your
                creation, sharing and publication of such User Content</span
              >.
            </li>
            <!--  -->
            <li>
              <span class="bold">Third-Party Services. </span>
              <br /><br />
              The App may contain links to third-party services that take you
              outside of the Company’s services (“<span class="bold"
                >Linked Services</span
              >”). We do not control, endorse, sponsor, recommend, or otherwise
              accept responsibility for such Linked Services, nor do we make any
              warranties or representations, express or implied, regarding the
              content (or the accuracy, currency, or completeness of such
              content) on any Linked Services. When you follow a link to another
              service, that service will be governed by different terms and a
              different privacy policy. You should be sure that you read and
              agree to those policies.<br /><br />
              Your correspondence or business dealings with, or participation in
              promotions of, advertisers or other third parties (if any) found
              on or throughout the App or its services, including, without
              limitation, with respect to the payment and delivery of related
              products or services and any other terms, conditions, warranties,
              or representations associated with such dealings, are solely
              between you and such third parties and are not binding on us. You
              agree that we are not responsible or liable for any actions,
              losses, damages, liabilities, claims, judgments, costs, or
              expenses of any nature or kind incurred as the result of any such
              dealings or as the result of the presence of such third parties on
              the App or its services, and you agree to indemnify us from and
              against any claims incurred as the result of any such dealings.
            </li>
            <!--  -->
            <li>
              <span class="bold"
                >Digital Millennium Copyright Act Notice.
              </span>
              <br /><br />
              <ul>
                <li>
                  Company respects the intellectual property of others and
                  requires that you do the same. In accordance with the Digital
                  Millennium Copyright Act (“<span class="bold">DMCA</span>”),
                  the text of which may be found on the U.S. Copyright Office
                  website at http://www.copyright.gov/legislation/dmca.pdf,
                  Company will respond expeditiously to notices of alleged
                  copyright infringement that are duly reported to its
                  Designated Copyright Agent identified in the notice below.
                  Company will disable and/or remove access to the App or its
                  services for users who are repeat infringers. If you believe
                  that your content has been copied in a way that constitutes
                  copyright infringement, or your intellectual property rights
                  have been otherwise violated, please provide Company’s
                  Designated Copyright Agent with the following information:
                  <br /><br />
                  DMCA Notice of Alleged Infringement (“<span class="bold"
                    >Notice</span
                  >”)
                  <ul>
                    <li>
                      Identify the copyrighted work that you claim has been
                      infringed, or if multiple works are covered by this Notice
                      you may provide a representative list of the copyrighted
                      works that you claim have been infringed.
                    </li>
                    <li>
                      Identify the material, or reference or link to material or
                      activity, you claim is infringing and provide a
                      description of where the infringing work, or reference or
                      link to infringing material or activity, is located on the
                      App or its services.
                    </li>
                    <li>
                      Provide your mailing address, telephone number and, if
                      available, email address.
                    </li>
                    <li>
                      Include both of the following statements in the body of
                      the Notice:
                      <br /><br />
                      <ul>
                        <li>
                          “I hereby state that I have a good-faith belief that
                          the disputed use of the copyrighted material is not
                          authorized by the copyright owner, its agent, or the
                          law (e.g., fair use).”
                        </li>
                        <li>
                          “I hereby state that the information in this Notice is
                          accurate and, under penalty of perjury, that I am the
                          owner or authorized to act on behalf of the owner, of
                          the copyright or of an exclusive right under the
                          copyright that is allegedly infringed.”
                        </li>
                      </ul>
                    </li>
                    <li>
                      In the event any of the copyrighted work(s) you claim has
                      been infringed is made available on the App or its
                      services by a person other than Company and system caching
                      as further set forth in the DMCA applies, also include the
                      following statement with respect to such work(s) in the
                      body of the Notice:
                      <ul>
                        <li>
                          “I hereby confirm that the material has been removed
                          from the originating site or access to it has been
                          disabled or that a court has ordered that the material
                          be removed from the originating site or that access to
                          the material on the originating site be disabled.”
                        </li>
                      </ul>
                    </li>
                    <li>
                      Provide your full legal name and your electronic or
                      physical signature.
                    </li>
                    <li>
                      Deliver this Notice, with all items completed, to
                      Company’s Designated Copyright Agent:<br /><br />
                      Copyright Agent <br />
                      Hello My Name Is Digital, Inc. doing business as Powderkeg
                      Media<br />
                      3500 West Olive Avenue, Suite<br />
                      610 Burbank, CA 91505<br />
                      Phone: (917) 267-8467 <br />
                      Email:
                      <a href="mailto:admin@yourmrright.app"
                        >admin@yourmrright.app</a
                      ><br />
                    </li>
                    <li>
                      While Company considers all such notices seriously, you
                      may be liable for damages (including costs and attorneys’
                      fees) if you materially misrepresent that content or
                      activity is infringing. Accordingly, if you are uncertain
                      whether material infringes your copyrights (including
                      whether use of copyrighted material may constitute fair
                      use), you may wish to seek the advice of an attorney.
                    </li>
                  </ul>
                </li>
                <!--  -->
                <li>
                  <span class="bold">Digital Rights Management. </span>
                  <br /><br />
                  We may use certain security technologies to prevent
                  unauthorized access to and unauthorized viewing or use of
                  certain content in the App. You agree not to, and agree not to
                  assist others that are attempting to, circumvent any of this
                  security technology.
                </li>
                <!--  -->
                <li>
                  <span class="bold">Termination. </span>
                  <br /><br />
                  We may limit, suspend, terminate, or discontinue the App or
                  any of its features or content, at any time, for any reason,
                  without notice.
                </li>
                <!--  -->
                <li>
                  <span class="bold">Disclaimer of Warranties. </span>
                  <br /><br />
                  THE APP AND ANY INCLUDED SOFTWARE ARE PROVIDED "AS IS",
                  WITHOUT WARRANTIES, EXPRESS OR IMPLIED, OF ANY KIND BY EITHER
                  COMPANY OR ITS LICENSORS. COMPANY AND ITS LICENSORS MAKE NO
                  WARRANTY, EITHER EXPRESS OR IMPLIED, AS TO THE ACCURACY,
                  AVAILABILITY, COMPLETENESS, USEFULNESS, SECURITY, RELIABILITY,
                  OR INTEROPERABILITY OF THE APP, OR THAT THE APP WILL BE
                  UNINTERRUPTED, VIRUS FREE, OR COMPATIBLE WITH YOUR DEVICE OR
                  THAT THE APP WILL MEET YOUR EXPECTATIONS AT ALL.
                </li>
                <!--  -->
                <li>
                  <span class="bold">Limitation of Liability. </span>
                  <br /><br />
                  THE FOLLOWING LIMITATIONS SHALL APPLY TO YOU TO THE MAXIMUM
                  EXTENT PERMITTED BY APPLICABLE LAW. UNDER NO CIRCUMSTANCES
                  SHALL WE BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
                  CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES ARISING OUT OF OR
                  IN CONNECTION WITH YOUR ACCESS OR USE OF OR INABILITY TO
                  ACCESS OR USE THE APP OR ITS SERVICES, WHETHER OR NOT THE
                  DAMAGES WERE FORESEEABLE AND WHETHER OR NOT A PARTY WAS
                  ADVISED OF THE POSSIBILITY OF SUCH DAMAGES INCLUDING, WITHOUT
                  LIMITATION, DAMAGES FOR LOSS OF PROFITS OR REVENUES, FAILURE
                  TO TRANSMIT OR RECEIVE ANY DATA, LOSS, MISUSE OR DISCLOSURE OF
                  CONFIDENTIAL INFORMATION, BUSINESS INTERRUPTION, LOSS OF
                  PRIVACY, CORRUPTION OR LOSS OF DATA, FAILURE TO RECEIVE OR
                  BACK UP YOUR DATA (OR ARCHIVED DATA) OR ANY OTHER PECUNIARY
                  LOSS WHATSOEVER ARISING OUT OF OR IN ANY WAY RELATED TO THE
                  APP. THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE
                  STATED REMEDY FAILS OF ITS ESSENTIAL PURPOSE.
                </li>
                <!--  -->
                <li>
                  <span class="bold">Indemnification. </span>
                  <br /><br />
                  You agree to indemnify, defend and hold Company harmless from
                  any claim, proceeding, loss, damage, liability or expense of
                  any kind arising out of or in connection with the following:
                  (a) your use or misuse of the App or its services; (b) your
                  alleged or actual breach of this Agreement; (c) your alleged
                  or actual violation of applicable rules, laws or regulations;
                  (d) your negligence or willful misconduct; or (e) your alleged
                  or actual infringement of the intellectual property or other
                  rights of third parties. We reserve the right, at your
                  expense, to assume the exclusive defense and control of any
                  matter which you are required to indemnify, and you agree to
                  cooperate in that defense.
                </li>
                <!--  -->
                <li>
                  <span class="bold">Severability. </span>
                  <br /><br />
                  If any provision of this Agreement is held in whole or in part
                  to be invalid, void or unenforceable in any jurisdiction for
                  any reason, that provision shall be modified to the extent
                  (but only to the extent) necessary to bring it within legal
                  requirements, and as so modified this Agreement shall continue
                  in full force and effect.
                </li>
                <!--  -->
                <li>
                  <span class="bold"
                    >Dispute Resolution: Arbitration and Waiver of Jury
                    Trial.</span
                  >
                  <br /><br />
                  <span class="bold"
                    >PLEASE READ THIS PARAGRAPH CAREFULLY. IT MAY SIGNIFICANTLY
                    AFFECT YOUR LEGAL RIGHTS, INCLUDING, BUT NOT LIMITED TO,
                    YOUR RIGHT TO FILE A LAWSUIT IN COURT</span
                  >.<br /><br />
                  You and Company both agree that any and all disputes relating
                  to or arising from this Agreement or the Privacy Policy,
                  including, without limitation, disputes regarding the validity
                  of this arbitration clause or your use of the App or its
                  services, including, without limitation, any disputes you may
                  have with our employees or agents, shall be settled by a
                  single arbitrator in an arbitration administered by JAMS in
                  accordance with the JAMS Streamlined Arbitration Rules and
                  Procedures (“<span class="bold">JAMS Rules</span>”), and
                  judgment on the award rendered by the arbitrator may be
                  entered in any court having jurisdiction thereof. You and
                  Company also both agree that: <br /><br />
                  <ul>
                    <li>
                      The Federal Arbitration Act applies to this Agreement. You
                      can also bring any issues you may have to the attention of
                      federal, state, or local government agencies, and if the
                      law allows, they can seek relief against us for you. This
                      agreement to arbitrate continues to apply even after you
                      have stopped using the App or receiving service from
                      Company.
                    </li>
                    <li>
                      The arbitration shall take place in Los Angeles,
                      California. The arbitration shall be governed by the laws
                      of the State of California without reference to choice of
                      law rules. You can get procedures, rules and fee
                      information from the JAMS (www.jamsadr.com) or from us. If
                      there is a conflict between the JAMS Rules and the rules
                      set forth in this Agreement, the rules set forth in this
                      Agreement will govern. Each party shall bear its own costs
                      and expenses and an equal share of the arbitrator’s and
                      administrative fees of arbitration.
                    </li>
                    <li>
                      This Agreement doesn't allow class for collective
                      arbitrations even if the JAMS Rules would. Notwithstanding
                      any other provision of this Agreement, the arbitrator may
                      award money or injunctive relief only in favor of the
                      individual party seeking relief and only to the extent
                      necessary to provide relief warranted by that party's
                      individual claim; provided, however, that in no event may
                      injunctive or other equitable relief be awarded to you
                      that enjoins or restrains the development, production,
                      exhibition, distribution, advertising, marketing or other
                      exploitation of any content owned or controlled by
                      Company, and/or any elements thereof. No class or
                      representative or private attorney general theories of
                      liability or prayers for relief may be maintained in any
                      arbitration held under this Agreement.
                    </li>
                    <li>
                      If either you or Company intends to seek arbitration under
                      this Agreement, the party seeking arbitration must first
                      notify the other party of the dispute in writing at least
                      30 days in advance of initiating the arbitration. Notice
                      to Company shall be sent to Nametag Production, Inc., 3500
                      West Olive Avenue, Suite 610, Burbank, CA 91505. The
                      notice must describe the nature of the claim and the
                      relief being sought. If you and Company are unable to
                      resolve the dispute within 30 days, either party may then
                      proceed to file a claim for arbitration.
                    </li>
                    <li>
                      We may, but are not obligated to, make a written
                      settlement offer at any time before the arbitration
                      hearing. The amount or terms of any settlement offer may
                      not be disclosed to the arbitrator until after the
                      arbitrator issues an award on the claim. If you don't
                      accept the offer and the arbitrator awards you any amount
                      of money, or if we don't make you an offer and the
                      arbitrator awards you any amount of money, then we will
                      pay you only that amount awarded.
                    </li>
                    <li>
                      An arbitration award and any judgment confirming it apply
                      only to that specific case; the same cannot be used in any
                      other case except to enforce the award itself.
                    </li>
                    <li>
                      This agreement to mediation and/or arbitration is a final
                      and irrevocable waiver of the opportunity to file a
                      lawsuit in court to resolve any claims and the right to a
                      jury trial to the fullest extent permitted by law. You
                      should carefully consider this and other provisions of
                      this Agreement and the Privacy Policy and have the right
                      to consult legal counsel regarding this paragraph 20. If
                      for any reason a claim proceeds in court rather than
                      through arbitration, you and Company agree that there will
                      not be a jury trial. You and Company unconditionally waive
                      any right to trial by jury in any action, proceeding or
                      counterclaim arising out of or relating to this Agreement
                      in any way. In the event of litigation, this paragraph may
                      be filed to show a written consent to a trial by the
                      court.
                    </li>
                  </ul>
                </li>
                <!--  -->
                <li>
                  <span class="bold">Disputes Between Users; Release. </span>
                  <br /><br />
                  Any and all disputes between one user and another user of the
                  App or its services (“<span class="bold">User Disputes</span
                  >”) must be resolved between the parties to any such dispute.
                  Company will not get involved and is not responsible for any
                  interactions between users of the App or its services and need
                  not be informed of any User Disputes. For all such User
                  Disputes, and for all other matters relating to your use of
                  the App or its services, you hereby release and discharge
                  Company (and its officers, directors, affiliates, agents,
                  subsidiaries, joint ventures, and employees) from and against
                  any and all claims, obligations, liabilities, judgments,
                  demands, expenses, and damages (actual and consequential) of
                  every kind and nature, known and unknown, arising out of or in
                  any way connected with such User Disputes. If you are a
                  California resident, you hereby waive California Civil Code
                  Section 1542, which states: “A general release does not extend
                  to claims which the creditor or releasing party does not know
                  or suspect to exist in his favor at the time of executing the
                  release, which, if known by him must have materially affected
                  his settlement with the debtor or released party.”
                </li>
                <!--  -->
                <li>
                  <span class="bold">Export Compliance. </span>
                  <br /><br />
                  The App may be subject to U.S. export control laws and
                  regulations. You represent and warrant that (a) you are not
                  located in a country that is subject to a U.S. Government
                  embargo, or that has been designated by the U.S. Government as
                  a “terrorist supporting” country; and (b) you are not listed
                  on any U.S. Government list of prohibited or restricted
                  parties. You will not use, export or allow a third party to
                  use or export the App in any manner that would violate
                  applicable law, including, but not limited to, applicable
                  export control laws and regulations. You further agree to
                  comply with U.S. export laws concerning the transmission of
                  technical data and other regulated materials via the App.
                </li>
                <!--  -->
                <li>
                  <span class="bold">Driving Safety. </span>
                  <br /><br />
                  We are not responsible for use of the mobile phone while
                  driving. Safe driving is your responsibility and should always
                  be your first priority. Use of your mobile phone or other
                  electronic devices while driving can cause distractions, even
                  if you are using hands free devices. Consider turning off your
                  mobile phone and allowing calls to go to voicemail, and never
                  text or email while driving.
                </li>
                <!--  -->
                <li>
                  <span class="bold"
                    >Additional Terms Applicable to Apple Users.
                  </span>
                  <br /><br />
                  Notwithstanding any other provision within this Agreement, the
                  following additional terms are applicable to those using the
                  App on an Apple-branded product:
                  <br /><br />
                  <ul>
                    <li>
                      Company and you acknowledge that this Agreement is between
                      you and Company only, and not with Apple Inc. (“<span
                        class="bold"
                        >Apple</span
                      >”), and that Company (and/or any third-party developer
                      that may own and operate the App or a portion thereof) are
                      responsible for the App and the content thereof. You and
                      Company acknowledge and agree that Apple, and Apple’s
                      subsidiaries, are third-party beneficiaries of this
                      Agreement, and that, upon your acceptance of this
                      Agreement, Apple will have the right (and will be deemed
                      to have accepted the right) to enforce this Agreement
                      against you as a third-party beneficiary thereof.
                    </li>
                    <li>
                      You understand that the App may not be used in any manner
                      inconsistent with the Apple Media Services Terms and
                      Conditions as of the Effective Date (which you acknowledge
                      that you have had the opportunity to review). You may get
                      a copy of the Apple Media Services Terms and Conditions at
                      <a href="https://www.apple.com">www.apple.com</a> or from
                      us. Further, you must comply with applicable third-party
                      terms of agreement when using the App.
                    </li>
                    <li>
                      You are granted a non-transferable license to use the App
                      on any Apple-branded products that you own or control and
                      as permitted by the “Usage Rules” set forth in the Apple
                      Media Services Terms and Conditions, except that such App
                      may be accessed and used by other accounts associated with
                      the purchaser via family sharing or volume purchasing.
                    </li>
                    <li>
                      Company is solely responsible for providing any
                      maintenance and support services with respect to the App,
                      as specified in this Agreement or as required under
                      applicable law. Company and you acknowledge that Apple has
                      no obligation whatsoever to furnish any maintenance and
                      support services with respect to the App.
                    </li>
                    <li>
                      Company is solely responsible for any product warranties
                      in connection with the App, whether express or implied by
                      law, to the extent not effectively disclaimed. In the
                      event of any failure of the App to conform to any
                      applicable warranty, you may notify Apple, and Apple will
                      refund the purchase price for the App to you (if any);
                      and, to the maximum extent permitted by applicable law,
                      Apple will have no other warranty obligation whatsoever
                      with respect to the App, and any other claims, losses,
                      liabilities, damages, costs or expenses attributable to
                      any failure to conform to any warranty with respect to the
                      App will be Company’s sole responsibility. You and Company
                      acknowledge that Company, not Apple, is responsible for
                      addressing any claims made by you or any third party
                      relating to the App or your possession and/or use of the
                      App, including, but not limited to: (i) product liability
                      claims; (ii) any claim that the App fails to conform to
                      any applicable legal or regulatory requirement; and (iii)
                      claims arising under consumer protection, privacy or
                      similar legislation, including in connection with the
                      App’s use of the HealthKit and HomeKit frameworks.
                      Company’s liability is limited so far as permitted by
                      applicable law. Company and you acknowledge that, in the
                      event of any third-party claim that the App or your
                      possession and use of the App infringes that third party’s
                      intellectual property rights, Company, not Apple, will be
                      solely responsible for the investigation, defense,
                      settlement and discharge of any such intellectual property
                      infringement claim. If you have any questions or concerns
                      regarding the App, please contact us as described below.
                    </li>
                  </ul>
                </li>
                <!--  -->
                <li>
                  <span class="bold">Assignment.</span>
                  <br /><br />
                  You may not assign any rights granted to you or delegate any
                  of your duties hereunder and any attempt to do so is void and
                  of no effect. Company may assign its rights and delegate its
                  duties under this Agreement in their entirety in connection
                  with a merger, reorganization, or sale of all, or
                  substantially all, of its assets.
                </li>
                <!--  -->
                <li>
                  <span class="bold">Applicable Law.</span>
                  <br /><br />
                  This Agreement shall be governed by the laws of the State of
                  California, without reference to its choice of law rules. The
                  exclusive jurisdiction and venue for any action under this
                  Agreement will be in the state or federal courts located in
                  Los Angeles, California. You hereby accept the personal
                  jurisdiction of such courts.
                </li>
                <!--  -->
                <li>
                  <span class="bold">General. </span>
                  <br /><br />
                  Nothing in this Agreement constitutes a partnership or joint
                  venture between you and Company. Company’s failure to act with
                  respect to a breach by you or others does not waive our right
                  to act with respect to subsequent or similar breaches. This
                  Agreement sets forth the entire understanding and agreement
                  between you and Company with respect to the subject matter
                  hereof. Your representations, warranties, and indemnities
                  under this Agreement together with any clauses necessary for
                  the enforcement or interpretation of this Agreement
                  (including, but not limited to, the governing law and dispute
                  resolution provisions) or related to Company’s rights in, to
                  or with respect to the App shall survive the termination of
                  this Agreement.
                </li>
                <!--  -->
                <li>
                  <span class="bold">Contact Us. </span>
                  <br /><br />
                  If you have questions about this Agreement or the App, you may
                  contact Company at (917) 267-8467 or email us at
                  <a href="mailto:admin@yourmrright.app"
                    >admin@yourmrright.app</a
                  >. You may also contact us at:<br /><br />
                  Nametag Production, Inc.<br />
                  3500 West Olive Avenue, Suite 610<br />
                  Burbank, CA 91505<br />
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div id="footerArea">
      <the-footer></the-footer>
    </div>
  </div>
</template>

<script>
import TheNav from "@/components/TheNav.vue";
import TheFooter from "@/components/TheFooter.vue";
import SectionHeadline from "@/components/UI/SectionHeadline.vue";
import ModalDownload from "@/components/ModalDownload.vue";
import BackButton from "@/components/UI/BackButton.vue";
import "@/styles/index.scss";

export default {
  data() {
    return {
      logoVisible: true,
      modalVisible: false,
    };
  },
  name: "TermsOfUse",
  metaInfo: {
    title: "Terms of Use | ",
  },
  components: {
    TheNav,
    TheFooter,
    SectionHeadline,
    ModalDownload,
    BackButton,
  },
  methods: {
    showModal() {
      this.modalVisible = true;
      const bod = document.querySelector("body");
      bod.style.overflow = "hidden";
    },
    hideModal() {
      this.modalVisible = false;
      const bod = document.querySelector("body");
      bod.style.overflow = "visible";
    },
  },
};
</script>

<style scoped>
.back-button {
  margin-bottom: 60px;
}

.modal {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  overflow: hidden;
  z-index: 1001;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  margin-top: 200px;
  text-align: left;
  width: 80%;
  max-width: 1000px;
  margin: 200px auto;
}

#outerContainer {
  background: #000;
  width: 100%;
  overflow: hidden;
}
#app {
  margin: 0 auto;
  text-align: center;
  color: #2c3e50;
  width: 100%;
}

h1,
h2,
h3,
p,
ul {
  color: #fff;
}

h1 {
  font-size: 80px;
  margin: 100px 0 20px;
}

h2 {
  font-size: 36px;
  margin: 40px 0px;
  line-height: 40px;
}

h3 {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.08em;
}

p {
  margin: 30px 0px;
  font-size: 16px;
  line-height: 20px;
}

ul {
  margin: 0 40px;
  list-style: disc;
}

ul.inner-list li {
  list-style: none;
}

ul.sub-head {
  list-style: none;
}

li {
  margin: 10px;
  line-height: 18px;
}

span.bold {
  font-weight: bold;
}
a {
  text-decoration: underline;
  color: #bdf771;
}

a:hover {
  animation: mouseHover 0.3s linear forwards;
}

@keyframes mouseHover {
  0% {
    cursor: url("../assets/images/ui/cursor-01.png") 50 50, auto;
  }
  8% {
    cursor: url("../assets/images/ui/cursor-02.png") 50 50, auto;
  }
  16% {
    cursor: url("../assets/images/ui/cursor-03.png") 50 50, auto;
  }
  24% {
    cursor: url("../assets/images/ui/cursor-04.png") 50 50, auto;
  }
  33% {
    cursor: url("../assets/images/ui/cursor-05.png") 50 50, auto;
  }
  41% {
    cursor: url("../assets/images/ui/cursor-06.png") 50 50, auto;
  }
  48% {
    cursor: url("../assets/images/ui/cursor-07.png") 50 50, auto;
  }
  56% {
    cursor: url("../assets/images/ui/cursor-08.png") 50 50, auto;
  }
  67% {
    cursor: url("../assets/images/ui/cursor-09.png") 50 50, auto;
  }
  77% {
    cursor: url("../assets/images/ui/cursor-10.png") 50 50, auto;
  }
  85% {
    cursor: url("../assets/images/ui/cursor-11.png") 50 50, auto;
  }
  100% {
    cursor: url("../assets/images/ui/cursor-12.png") 50 50, auto;
  }
}
@media screen and (max-width: 820px) {
  #nav {
    z-index: 5000;
    position: absolute;
  }

  .container {
    margin-top: 50px;
    width: 90%;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 24px;
    line-height: 26px;
  }
  ul {
    margin: 0 10px;
    list-style: disc;
  }
  p {
    font-size: 16px;
  }

  h3 {
    font-size: 16px;
  }

  #outerContainer {
    width: 100%;
  }

  #header {
    margin-top: 50px;
  }
  a:hover,
  a:active {
    animation: none;
  }
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 500px) {
  h3 {
    font-size: 15px;
  }
}

@media screen and (max-width: 500px) {
}

@media screen and (max-width: 900px) and (orientation: landscape) {
}
</style>
