<template>
  <div class="cast-card" :class="rotationClass">
    <div class="cast-card-top">
      <card-close></card-close>
      <card-camera></card-camera>
    </div>
    <div class="cast-card-body">
      <div class="cast-headshot" :class="headshot">
        <div class="cast-card-icon">
          <svg-icon :icoType="icon" classType="card"></svg-icon>
        </div>
      </div>
      <h3>{{ role }}</h3>
      <h2>{{ name }}</h2>
      <p>
        {{ blurb }}
      </p>
    </div>
  </div>
</template>

<script>
import CardClose from "./svg/CardClose.vue";
import CardCamera from "./svg/CardCamera.vue";
import SvgIcon from "./svg/SvgIcon.vue";

export default {
  name: "CastCard",
  props: {
    rotationClass: Object,
    name: String,
    role: String,
    blurb: String,
    icon: String,
    headshot: String,
  },
  components: { CardClose, CardCamera, SvgIcon },
};
</script>

<style scoped>
.cast-card {
  flex-shrink: 0;
  border: 3px solid #000;
  background: #fff;
  width: 375px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  box-shadow: 16px 16px 0px rgba(0, 0, 0, 0);
  transition: all 0.5s ease-in-out;
  margin-top: 40px;
}

.cast-card:hover {
  box-shadow: 16px 16px 0px rgba(0, 0, 0, 1);
}

.cast-card-icon {
  position: absolute;
  top: 30%;
  transform: translate(0%, -30%);
  right: -40px;
}

.cast-card-top {
  padding: 20px 20px;
  height: 52px;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background: #000;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.cast-card-body {
  padding: 30px 0px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.cast-card-body h2 {
  font-size: 36px;
  margin: 10px;
}
.cast-card-body h3 {
  margin: 10px;
  font-size: 14px;
  text-transform: uppercase;
}
.cast-card p {
  margin: 10px;
  width: 80%;
}

.cast-headshot {
  position: relative;
  border-radius: 50%;
  border: 3px solid #000;
  width: 239px;
  height: 239px;
  margin: 10px;
}

.odd {
  transform: rotate(-10.35deg);
  margin-left: -30px;
}

.even {
  transform: rotate(10.35deg);
  margin-left: -30px;
  margin-top: 150px;
}

.final {
  transform: rotate(2deg);
  margin-left: -30px;
}
.aimee {
  background: url("../assets/images/headshots/aimee.png");
  background-size: contain;
}

.sam {
  background: url("../assets/images/headshots/sam.png");
  background-size: contain;
}

.sterling {
  background: url("../assets/images/headshots/sterling.png");
  background-size: contain;
}

.mimi {
  background: url("../assets/images/headshots/mimi.png");
  background-size: contain;
}

.constance {
  background: url("../assets/images/headshots/constance.png");
  background-size: contain;
}

.blake {
  background: url("../assets/images/headshots/blake.png");
  background-size: contain;
}

.diana {
  background: url("../assets/images/headshots/diana.png");
  background-size: contain;
}

.typecode {
  background: url("../assets/images/headshots/typecode.png");
  background-size: contain;
}

.powderkeg {
  background: url("../assets/images/headshots/powderkeg.png");
  background-size: contain;
}

@media screen and (max-width: 600px) {
  .cast-card:first-of-type {
    margin-left: 0px;
  }

  .odd {
    transform: scale(0.8) rotate(-10.35deg);
  }

  .even {
    transform: scale(0.8) rotate(10.35deg);
    margin-top: 0px;
  }
  .final {
    transform: scale(0.8) rotate(2deg);
    margin-left: -30px;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .odd {
    transform: scale(0.7) rotate(-10.35deg);
  }

  .even {
    transform: scale(0.7) rotate(10.35deg);
    margin-top: 0px;
  }
  .final {
    transform: scale(0.7) rotate(2deg);
    margin-left: -30px;
  }
}
</style>
