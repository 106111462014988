import Vue from "vue";
import VueMeta from "vue-meta";

import Home from "@/pages/Home";
import DownloadPage from "@/pages/DownloadPage";
import PrivacyPage from "@/pages/PrivacyPage";
import TermsOfUse from "@/pages/TermsOfUse.vue";

import { isMobileDevice } from "@/assets/js/utils";

const routes = {
  "/": Home,
  "/download": DownloadPage,
  "/privacy-policy": PrivacyPage,
  "/terms-of-use": TermsOfUse,
};

Vue.use(VueMeta);

new Vue({
  el: "#app",
  data: {
    currentRoute: window.location.pathname,
  },
  metaInfo: {
    titleTemplate: "%s Mr. Right | The perfect man is a download away.",
  },
  computed: {
    ViewComponent() {
      if (this.currentRoute === "/download" && !isMobileDevice()) {
        window.location.href = window.location.origin;
        return Home;
      } else {
        return routes[this.currentRoute] || Home;
      }
    },
  },
  render(h) {
    return h(this.ViewComponent);
  },
});
