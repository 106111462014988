<template>
  <div>
    <!-- arrow left -->
    <svg
      v-if="direction === 'left'"
      width="14"
      height="22"
      viewBox="0 0 14 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.062 2L2.06201 11L11.062 20"
        stroke="black"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <!-- arrow right -->
    <svg
      v-if="direction === 'right'"
      width="14"
      height="22"
      viewBox="0 0 14 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.93799 20L11.938 11L2.93799 2"
        stroke="black"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </div>
</template>
<script>
export default {
  props: {
    direction: String,
  },
};
</script>

<style scoped>
div {
  display: inline;
}
</style>
