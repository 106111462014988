<template>
  <div class="mobileLinks">
    <a
      href="https://play.google.com/store/apps/details?id=com.frescher.mr_right&hl=en&gl=US"
      target="_blank"
      ><img
        src="../assets/images/play-store.png"
        alt="Get it on Google Play"
        @click="trackDownload"
    /></a>
    <a
      href="https://apps.apple.com/us/app/mr-right-interactive-rom-com/id6443460018"
      target="_blank"
      ><img
        src="../assets/images/app-store.png"
        alt="Download on The App Store"
        @click="trackDownload"
    /></a>
  </div>
</template>

<script>
export default {
  methods: {
    trackDownload() {
      /*global gtag*/
      gtag("event", "click", { label: "download app" });
    },
  },
};
</script>

<style scoped>
.mobileLinks {
  display: none;
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .mobileLinks {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: auto;
  }
  img {
    margin: 0px 5px;
  }
}

/* Tablet and Phone */
@media (max-width: 1024px) {
  .mobileLinks {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    height: auto;
  }
  img {
    margin: 0px 5px;
  }
}
</style>
