<template>
  <div class="container">
    <div class="modal-close" @click="closeModal">
      <modal-close></modal-close>
    </div>
    <div class="icons">
      <div class="icon-outer">
        <div class="icon icon-1">
          <svg-icon icoType="strawberry"></svg-icon>
        </div>
      </div>
      <div class="icon-outer">
        <div class="icon icon-2">
          <svg-icon icoType="dice" classType="card"></svg-icon>
        </div>
      </div>
      <div class="icon-outer">
        <div class="icon icon-3">
          <svg-icon icoType="wideeyes" classType="card"></svg-icon>
        </div>
      </div>
      <div class="icon-outer">
        <div class="icon icon-4">
          <svg-icon icoType="bitheart" classType="card"></svg-icon>
        </div>
      </div>
      <div class="icon-outer">
        <div class="icon icon-5">
          <svg-icon icoType="hearteyes" classType="card"></svg-icon>
        </div>
      </div>
      <div class="icon-outer">
        <div class="icon icon-6">
          <svg-icon icoType="daisy" classType="card"></svg-icon>
        </div>
      </div>
    </div>
    <div class="inner">
      <div class="content">
        <h3>Download Mr. Right for free</h3>
        <QRCode />
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "./svg/QRCode.vue";
import ModalClose from "./svg/ModalClose.vue";
import SvgIcon from "./svg/SvgIcon.vue";
export default {
  emits: ["close-modal"],
  name: "ModalDownload",
  components: {
    QRCode,
    ModalClose,
    SvgIcon,
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 950px;
  height: 520px;
  border-radius: 80px;
  background: #fff;
}

.inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 95%;
  height: 477px;
  border: 3px solid #000;
  border-radius: 60px;
  position: relative;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.icons {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.icon-1 {
  position: absolute;
  top: 250px;
  left: 50px;
  transform: scale(0.7);
}

.icon-2 {
  position: absolute;
  top: 200px;
  left: 150px;
  transform: scale(1.2);
}

.icon-3 {
  position: absolute;
  top: 300px;
  left: 220px;
  transform: scale(1.2) rotate(25deg);
}

.icon-4 {
  position: absolute;
  top: 350px;
  right: 200px;
  transform: scale(0.9) rotate(-10deg);
}

.icon-5 {
  position: absolute;
  top: 280px;
  right: 100px;
  transform: scale(1.1) rotate(15deg);
}

.icon-6 {
  position: absolute;
  top: 180px;
  right: 200px;
  transform: scale(0.7) rotate(15deg);
}

h3 {
  font-size: 48px;
  letter-spacing: -4%;
}

.modal-close {
  position: absolute;
  top: -60px;
  right: -60px;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
}
.modal-close:hover {
  box-shadow: 7px 7px 0px rgba(0, 0, 0, 1);
  cursor: url("../assets/images/ui/mousehover.png"), auto;
  transform: scale(1);
}

@media screen and (max-width: 1400px) {
  .modal-close {
    transform: scale(0.75);
    top: 40px;
    right: 40px;
  }
  .modal-close:hover {
    box-shadow: none;
    transition: none;
    transform: scale(0.75);
  }

  .icons,
  .inner {
    pointer-events: none;
  }
}
</style>
