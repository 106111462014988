<template>
  <div class="container">
    <transition name="show-hide-modal" v-if="modalVisible">
      <div class="modal">
        <div class="video">
          <div class="close-modal" @click="hideModal">
            <modal-close></modal-close>
          </div>
          <vimeo-player></vimeo-player>
        </div>
      </div>
    </transition>
    <div>
      <h2>Watch the <br />Mr. Right commercial</h2>
      <div class="winky">
        <winky-icon></winky-icon>
      </div>
      <div class="preview-container">
        <div @click="showModal">
          <PlayButton></PlayButton>
          <h3>Play video</h3>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PlayButton from "./svg/PlayButton.vue";
import VimeoPlayer from "./VimeoPlayer.vue";
import ModalClose from "./svg/ModalClose.vue";
import WinkyIcon from "./svg/WinkyIcon.vue";

export default {
  data() {
    return {
      modalVisible: false,
    };
  },
  name: "MovieTrailer",
  components: { PlayButton, VimeoPlayer, ModalClose, WinkyIcon },
  props: {},
  methods: {
    showModal() {
      this.modalVisible = true;
      const bod = document.querySelector("body");
      bod.style.overflow = "hidden";
    },
    hideModal() {
      this.modalVisible = false;
      const bod = document.querySelector("body");
      bod.style.overflow = "visible";
    },
  },
};
</script>

<style scoped>
.container {
  position: relative;
  margin: 250px auto 150px;
  width: 95%;
}

.winky {
  width: 100%;
  margin: 0px 0px 60px;
}

.preview-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 700px;
  border: 10px solid #000;
  box-shadow: 35px 35px 0px rgb(0, 0, 0);
  border-radius: 16px;
  background: #000 url("../assets/images/trailer/trailer_thumbnail.jpg")
    no-repeat;
  background-size: cover;
  max-width: 1200px;
  margin: 0 auto;
}

/* Tablet and Phone */
@media (hover: none) {
  .preview-container {
    max-width: 90%;
  }
}

.modal {
  background: rgba(0, 0, 0, 0.9);
  position: fixed;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  z-index: 1001;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.show-hide-modal-enter-from {
  opacity: 0;
}

.show-hide-modal-enter-active {
  transition: all 10.5s ease-out;
}

.show-hide-modal-enter-to {
  opacity: 1;
}

.show-hide-modal-leave-from {
  opacity: 1;
}

.show-hide-modal-leave-active {
  transition: all 0.5s ease-in;
}

.show-hide-modal-leave-to {
  opacity: 0;
}

.close-modal {
  transform: scale(0.75);
  position: absolute;
  top: -60px;
  right: -60px;
  z-index: 1000;
  transition: all 0.2s ease-in-out;
  border-radius: 50%;
}

.close-modal:hover {
  cursor: url("../assets/images/ui/mousehover.png"), auto;
  box-shadow: 7px 7px 0px rgba(0, 0, 0, 1);
}

.video {
  position: relative;
  width: 80%;
  height: auto;
  max-width: 1400px;
  vertical-align: middle;
}

h2 {
  letter-spacing: -0.04em;
  color: #000;
  font-size: 80px;
  line-height: 100%;
  margin: 20px 0px;
}
h2 span {
  display: inline-block;
}

h3 {
  letter-spacing: -0.04em;
  color: #fff;
  font-size: 36px;
  margin: 15px 0px;
}

@media screen and (max-width: 768px) {
  .container {
    position: relative;
    margin: 150px auto 150px;
    height: auto;
  }

  .preview-container {
    margin-left: -17px;
    background-position: 50% top;
    width: 75%;
    margin: 0 auto;
  }

  .close-modal {
    transition: none;
    right: 0px;
    top: 0px;
    transform: scale(0.5);
    transition: none;
  }
  .close-modal:active,
  .close-modal:hover {
    transition: none;
    transform: scale(0.5);
  }
  .video {
    width: 100%;
    vertical-align: middle;
  }
  h2 {
    font-size: 36px;
  }
}

@media screen and (max-width: 500px) {
  .preview-container {
    width: 80%;
    height: 625px;
    margin: 0 auto;
    background-position: 50% top;
    border: 5px solid #000;
    box-shadow: 16px 16px 0px rgb(0, 0, 0);
  }
  h2 {
    font-size: 30px;
  }
}

@media screen and (max-width: 600px) {
  .container {
    margin: -20px auto 100px;
  }
  .winky {
    margin: 0 0 20px;
  }
  .winky svg {
    transform: scale(0.7);
  }
  .close-modal {
    transition: none;
    right: 0px;
    top: 0px;
    transform: scale(0.4);
    box-shadow: none;
  }
  .close-modal:active,
  .close-modal:hover {
    transition: none;
    transform: scale(0.4);
    box-shadow: none;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .close-modal {
    transition: none;
    right: 0px;
    top: 0px;
    transform: scale(0.4);
    box-shadow: none;
  }
  .close-modal:active,
  .close-modal:hover {
    transition: none;
    transform: scale(0.4);
    box-shadow: none;
  }
  h2 {
    font-size: 50px;
  }
  .preview-container {
    width: 75%;
    background-position: 50% top;
  }
}
</style>
