<template>
  <div class="headline" :class="className">{{ headLine }}</div>
</template>

<script>
export default {
  name: "SectionHeadline",
  props: {
    headLine: String,
    className: String,
  },
};
</script>

<style scoped>
.headline {
  font-size: 36px;
  border-radius: 36px;
  border: 3px solid #000;
  padding: 16px 48px;
  display: inline-block;
  color: #000;
  box-shadow: 9px 9px 0px rgb(0, 0, 0);
}

.main-header {
  background: #bdf771;
}

.synopsis,
.terms {
  background: #f97ea2;
}

.cast-members {
  background: #b8bfff;
}

@media screen and (max-width: 800px) {
  .headline {
    font-size: 28px;
    line-height: 28px;
    padding: 12px 32px;
  }
}
</style>
