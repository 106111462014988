<template>
  <div id="download-page"></div>
</template>
<script>
/* eslint-disable */
import { getDeviceType, isMobileDevice } from "@/assets/js/utils";

export default {
  name: "DownloadPage",
  data() {
    return {
      isStale: false,
    };
  },
  metaInfo: {
    title: "Download | ",
  },
  created() {
    window.addEventListener("focus", this.handleFocus);
  },
  beforeUnmount() {
    window.removeEventListener("focus", this.handleFocus);
  },
  mounted() {
    const trackedClick = window.location.search.includes("type=click");
    if (isMobileDevice()) {
      /*global gtag*/
      !trackedClick && gtag("event", "qr_code_scan", { label: "download app" });

      const appStoreLink =
        "https://apps.apple.com/us/app/mr-right-interactive-rom-com/id6443460018";
      const playStoreLink =
        "https://play.google.com/store/apps/details?id=com.frescher.mr_right&hl=en&gl=US";

      window.location.href =
        getDeviceType() == "ios" ? appStoreLink : playStoreLink;
    }

    this.$nextTick(() => {
      this.isStale = true;
      if (navigator.platform == "iPad") {
        setTimeout(() => {
          window.location.href = window.location.origin;
        }, 100);
      }
    });
  },
  methods: {
    handleFocus() {
      if (this.isStale) {
        window.location.href = window.location.origin;
      }
    },
  },
};
</script>

<style>
#download-page {
  background: #fff;
  min-height: 100vh;
}
</style>
