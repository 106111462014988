<template>
  <div id="navContainer">
    <nav>
      <transition name="ig-logo" mode="out-in">
        <div style="width: 200px" v-if="nohover" @mouseenter="hoverLogo(true)">
          <a
            href="https://instagram.com/mr.right.app?igshid=Nzg3NjI1NGI="
            target="_blank"
            ><IGNavLogo
          /></a>
        </div>
        <div style="width: 200px" v-else @mouseleave="hoverLogo(false)">
          <a
            href="https://instagram.com/mr.right.app?igshid=Nzg3NjI1NGI="
            target="_blank"
            ><IGNavLogoHover
          /></a>
        </div>
      </transition>
      <a href="/"
        ><div
          class="logo"
          :class="{ hidden: !logoVisible, visible: logoVisible }"
        >
          <logo-nav></logo-nav></div
      ></a>
      <div v-if="!isMobile" @click="openModal"><nav-button></nav-button></div>
      <div v-else><nav-button :isMobile="isMobile"></nav-button></div>
    </nav>
  </div>
</template>
<script>
import NavButton from "./UI/NavButton.vue";
import LogoNav from "./svg/Logo_Nav.vue";
import IGNavLogo from "./svg/IGNavLogo.vue";
import IGNavLogoHover from "./svg/IGNavLogoHover.vue";

import { isMobileDevice } from "@/assets/js/utils";

export default {
  data() {
    return {
      nohover: true,
    };
  },
  emits: ["open-modal"],
  name: "TheNav",
  props: {
    logoVisible: {
      type: Boolean,
    },
  },
  components: { LogoNav, IGNavLogo, IGNavLogoHover, NavButton },
  computed: {
    isMobile() {
      return isMobileDevice();
    },
  },
  methods: {
    openModal() {
      this.$emit("open-modal");
    },
    hoverLogo(hover) {
      hover === true ? (this.nohover = false) : (this.nohover = true);
    },
  },
};
</script>

<style scoped>
#navContainer {
  width: 100%;
  position: fixed;
  top: 30px;
  z-index: 1000;
}
nav {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-items: center;
  padding: 0px 40px;
  max-width: 1200px;
  height: 80px;
  border-radius: 20px;
  background: #000;
}

.logo {
  transition: all 0.2s ease-in-out;
}
.logo.hidden {
  opacity: 0;
}
.logo.visible {
  opacity: 1;
}

@media screen and (max-width: 1200px) {
  nav {
    width: 90%;
  }
}

@media screen and (max-width: 768px) {
  #navContainer {
    pointer-events: none;
    top: 0px;
    margin: 0 auto;
    width: 100%;
    height: 97%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  nav {
    pointer-events: all;
    padding: 0px 20px;
    width: 90%;
  }
  .logo {
    display: none;
  }
}

@media screen and (max-width: 450px) {
  nav {
    width: 90%;
  }
  .logo {
    display: none;
  }
}

@media screen and (max-width: 320px) {
  nav {
    padding: 0px 10px;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  #navContainer {
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    justify-items: flex-end;
    justify-content: flex-end;
    bottom: 10px;
  }
  nav {
    pointer-events: all;
    padding: 0px 20px;
    width: 90%;
  }
  .logo {
    display: none;
  }
}
</style>
