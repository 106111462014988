<template>
  <div class="footer">
    <div class="outer">
      <ul class="footer-left">
        <li>All Rights Reserved &copy; 2023 Powderkeg</li>
      </ul>
      <div class="pk-logo">
        <img src="../assets/images/powderkeg-logo.png" alt="POWDERKEG" />
      </div>
      <ul class="footer-right">
        <li><a href="/terms-of-use">Terms of Use</a></li>
        <li><a href="/privacy-policy">Privacy Policy</a></li>
      </ul>
      <div class="footer-logo"><logo-nav></logo-nav></div>
    </div>
  </div>
</template>

<script>
import LogoNav from "./svg/Logo_Nav.vue";
export default {
  name: "TheFooter",
  components: { LogoNav },
};
</script>

<style scoped>
.footer div.outer {
  height: 120px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  padding: 0px 40px;
  margin: -20px 0 0px;
}
.footer {
  background: #000;
}
.footer-logo {
  display: none;
}

.footer-right {
  color: #bdf771;
}
li {
  display: inline-block;
  margin: 0px 20px;
}
li a {
  color: #bdf771;
  text-decoration: underline;
}
li a:hover {
  animation: mouseHover 0.3s linear forwards;
}

@keyframes mouseHover {
  0% {
    cursor: url("../assets/images/ui/cursor-01.png") 50 50, auto;
  }
  8% {
    cursor: url("../assets/images/ui/cursor-02.png") 50 50, auto;
  }
  16% {
    cursor: url("../assets/images/ui/cursor-03.png") 50 50, auto;
  }
  24% {
    cursor: url("../assets/images/ui/cursor-04.png") 50 50, auto;
  }
  33% {
    cursor: url("../assets/images/ui/cursor-05.png") 50 50, auto;
  }
  41% {
    cursor: url("../assets/images/ui/cursor-06.png") 50 50, auto;
  }
  48% {
    cursor: url("../assets/images/ui/cursor-07.png") 50 50, auto;
  }
  56% {
    cursor: url("../assets/images/ui/cursor-08.png") 50 50, auto;
  }
  67% {
    cursor: url("../assets/images/ui/cursor-09.png") 50 50, auto;
  }
  77% {
    cursor: url("../assets/images/ui/cursor-10.png") 50 50, auto;
  }
  85% {
    cursor: url("../assets/images/ui/cursor-11.png") 50 50, auto;
  }
  100% {
    cursor: url("../assets/images/ui/cursor-12.png") 50 50, auto;
  }
}

@media screen and (max-width: 1000px) {
  ul {
    text-align: center;
  }
}

@media screen and (max-width: 800px) and (orientation: portrait) {
  .footer div.outer {
    height: 400px;
    flex-direction: column-reverse;
    padding: 40px 0px;
    justify-content: flex-end;
  }
  ul {
    margin: 10px 0px;
    padding: 0;
    text-align: center;
  }
  .footer-logo {
    display: block;
  }
  .pk-logo {
    margin: 10px 0px;
  }
}
@media screen and (max-width: 900px) and (orientation: landscape) {
  .footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 200px;
  }
  ul {
    margin: 10px 0px;
    padding: 0;
    text-align: center;
  }
  .footer div.outer {
    margin-top: -10px;
  }
  .footer-right {
    align-self: center;
  }
}
</style>
