<template>
  <div class="container">
    <div class="arrows">
      <div class="arrow left-arrow" @click="onSlidePrev">
        <div><drag-arrow direction="left"></drag-arrow></div>
      </div>
      <div class="arrow right-arrow" @click="onSlideNext">
        <div><drag-arrow direction="right"></drag-arrow></div>
      </div>
    </div>
    <swiper
      slides-per-view="auto"
      :space-between="10"
      :loop="true"
      :allowSlideNext="true"
      @click="onSlideClick"
    >
      <swiper-slide
        v-for="member in castmembers"
        :key="member.id"
        :index="member.index"
      >
        <div class="slide-holder" @mouseenter="cardHover">
          <cast-card
            :name="member.name"
            :blurb="member.blurb"
            :role="member.role"
            :rotationClass="{
              even: member.index % 2 === 0,
              odd: member.index % 2 !== 0,
              final: member.index % 9 === 0,
            }"
            :icon="member.icon"
            :headshot="member.headshot"
          ></cast-card>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Navigation, Pagination } from "swiper";

import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import CastCard from "./CastCard.vue";
import DragArrow from "./svg/DragArrow.vue";
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination]);

export default {
  data() {
    return {
      currZ: 5000,
      castmembers: [
        {
          id: "1",
          index: 1,
          name: "Aimee Carerro",
          role: "Sophia",
          headshot: "aimee",
          blurb:
            "Aimee Carrero has shown her versatile talent across many media, including her successful voice acting as the first Latina princess with Disney’s “Elena of Avalor.” She can currently be seen  in Fox Searchlight’s “The Menu” as Felicity, and in the Apple+ film “Spirited” opposite Ryan Reynolds and Will Ferrell. Carrero has received acclaim for her role in the Netflix limited series “Maid” opposite Margaret Qualley, which earned her an Imagen Award nomination. Earlier this year, she was also seen in the Paramount+ series “The Offer,” and recently completed production on Amazon’s original series “The Consultant” opposite Christoph Waltz.",
          icon: "bitheart",
        },
        {
          id: "2",
          index: 2,
          name: "Sam Asghari",
          role: "Rob",
          headshot: "sam",
          blurb:
            "Sam has a multi-episode run in the Emmy-nominated Showtime series “Black Monday.” He can also be seen in the Emmy-winning HBO Max series “Hacks.” Asghari stars in the Lionsgate action-thriller “Hot Seat” alongside Mel Gibson and Kevin Dillon.  Sam will next be seen recurring in “Lioness,” the Taylor Sheridan drama series for Paramount+, starring Zoe Saldaña. Sam lost 100 pounds shortly after graduating high school, inspiring others to be more health positive. He worked with Pledge World to create a charity for underprivileged youth, building nutrition-based programs and training mentorships.",
          icon: "robot",
        },
        {
          id: "3",
          index: 3,
          name: "Sterling Sulieman",
          role: "Jay",
          headshot: "sterling",
          blurb:
            "Sterling is an actor from Oahu, Hawaii. He recently finished shooting Comedy Central’s “Cursed Friends,” as well as the indies “Scrambled” and “The Engagement Dress.” Sterling can be seen recurring on the CBS series “All Rise,” Amazon’s reboot of “I Know What You Did Last Summer” and as a love interest in the “Grey’s Anatomy” spinoff “Station 19.” Before that, he was the lead of the Shonda Rhimes ABC series “Still Star Crossed.”",
          icon: "hearteyes",
        },
        {
          id: "4",
          index: 4,
          name: "Mimi Gianopolous",
          role: "Tara",
          headshot: "mimi",
          blurb:
            "Mimi Gianopulos was born and raised in Los Angeles and is a graduate of Carnegie Mellon University’s School of Drama. She is best known for recurring roles on “Rutherford Falls,” “American Princess,” “Baby Daddy” and most recently “The Offer” on Paramount+. You can see her next in “Loss & Found.”",
          icon: "daisy",
        },
        {
          id: "5",
          index: 5,
          name: "Constance Marie",
          role: "Gloria",
          headshot: "constance",
          blurb:
            "Constance Marie is perhaps best known for her beloved portrayal of wife Angie Lopez in the ABC comedy series “George Lopez.” She currently stars in the Latinx romantic comedy series “With Love” and the rotoscope dramedy “Undone,” both in their second seasons for Amazon Prime Video. ",
          icon: "yinyang",
        },
        {
          id: "6",
          index: 6,
          name: "Blake Cooper Griffin",
          role: "Caleb",
          headshot: "blake",
          blurb:
            "Blake currently appears in Ryan Murphy’s Netflix hit “Monster: The Jeffrey Dahmer Story” and HBO’s “Winning Time” opposite John C. Reilly. Blake has appeared in slew of television shows, most recently TNT’s “Animal Kingdom,” Marvel’s “Cloak & Dagger,” AMC’s “Preacher” and as the star of CW Seed’s reboot of the cult classic “Beerfest.”",
          icon: "lightning",
        },
        {
          id: "7",
          index: 7,
          name: "Powderkeg",
          role: "ProDucer",
          headshot: "powderkeg",
          blurb:
            "Launched in 2018 by DGA Award winning and Emmy Award nominated filmmaker Paul Feig and Emmy Award winning producer Laura Fischer, Powderkeg Media champions new voices with a special commitment to female, LGBTQ creators, disabled, and filmmakers of color by developing scripted and unscripted formats across emerging and traditional platforms.",
          icon: "strawberry",
        },
        {
          id: "8",
          index: 8,
          name: "Diana Snyder Ritter",
          role: "Director & Writer",
          headshot: "diana",
          blurb:
            "Diana started her career writing on the Freeform series “Young & Hungry”. Since then she has sold pilots to HBO with Will Packer Media producing, Disney with Zoe Saldana producing, and Warner Bros. with Margot Robbie producing. She also sold a Christmas movie to Freeform and a movie to Sam Levinson’s company (creator of “Euphoria”). In addition to “Mr.Right,” Diana is currently writing a pilot for Paramount+ with Paramount Studios and CJ ENM producing.",
          icon: "smile",
        },
        {
          id: "9",
          index: 9,
          name: "TYPE / CODE",
          role: "App Design / Development",
          headshot: "typecode",
          blurb:
            "Type/Code designs and builds digital products and experiences. We collaborate with ambitious clients to bring powerful ideas to life. We find clever solutions to hard problems. We love to immerse ourselves in new domains. We strive to make things beautiful, intuitive, functional and effective.",
          icon: "butterfly",
        },
      ],
    };
  },
  name: "CastCarousel",
  components: {
    Swiper,
    SwiperSlide,
    CastCard,
    DragArrow,
  },
  methods: {
    onSlideClick(swiper) {
      this.currZ++;
      swiper.slides[swiper.clickedIndex].firstChild.style.zIndex =
        this.currZ + 1;
    },
    onSlideNext() {
      const swiper = document.querySelector(".swiper-container").swiper;
      swiper.slideNext(500, false);
    },
    onSlidePrev() {
      const swiper = document.querySelector(".swiper-container").swiper;
      swiper.slidePrev(500, false);
    },
    cardHover(event) {
      this.currZ++;
      event.target.parentElement.style.zIndex = this.currZ + 1;
    },
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.container {
  margin-top: 100px;
}

.container .arrows {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
}

.container .arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 50px;
  height: 50px;
  background: #bdf771;
  border: 2px solid black;
  z-index: 10;
  border-radius: 50%;
}

.container .arrows .left-arrow {
  left: 50px;
  top: 40%;
}

.container .arrows .right-arrow {
  right: 50px;
  top: 40%;
}

.left-arrow div,
.right-arrow div {
  transform: scale(0.6);
}

.left-arrow:hover,
.right-arrow:hover {
  box-shadow: 5px 5px 0px #000;
  animation: mouseHover 0.3s linear forwards;
}

@keyframes mouseHover {
  0% {
    cursor: url("../assets/images/ui/cursor-01.png") 50 50, auto;
  }
  8% {
    cursor: url("../assets/images/ui/cursor-02.png") 50 50, auto;
  }
  16% {
    cursor: url("../assets/images/ui/cursor-03.png") 50 50, auto;
  }
  24% {
    cursor: url("../assets/images/ui/cursor-04.png") 50 50, auto;
  }
  33% {
    cursor: url("../assets/images/ui/cursor-05.png") 50 50, auto;
  }
  41% {
    cursor: url("../assets/images/ui/cursor-06.png") 50 50, auto;
  }
  48% {
    cursor: url("../assets/images/ui/cursor-07.png") 50 50, auto;
  }
  56% {
    cursor: url("../assets/images/ui/cursor-08.png") 50 50, auto;
  }
  67% {
    cursor: url("../assets/images/ui/cursor-09.png") 50 50, auto;
  }
  77% {
    cursor: url("../assets/images/ui/cursor-10.png") 50 50, auto;
  }
  85% {
    cursor: url("../assets/images/ui/cursor-11.png") 50 50, auto;
  }
  100% {
    cursor: url("../assets/images/ui/cursor-12.png") 50 50, auto;
  }
}

#drag {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: absolute;
  bottom: -30px;
}

.swiper-slide {
  width: 350px;
}
.slide-holder {
  position: relative;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.w-100 {
  width: 100%;
}
.ml-auto,
.mx-auto {
  margin-left: auto;
}
.mr-auto,
.mx-auto {
  margin-right: auto;
}

@media screen and (max-width: 768px) {
  .container {
    margin-top: 30px;
  }
  .left-arrow:hover,
  .right-arrow:hover {
    box-shadow: none;
  }
  .container .arrows .left-arrow {
    left: 20px;
    top: 50%;
  }

  .container .arrows .right-arrow {
    right: 20px;
    top: 50%;
  }
}

@media screen and (max-width: 800px) {
  .container {
    display: flex;
    flex-direction: column-reverse;
  }
}
@media screen and (max-width: 600px) {
  .swiper-slide {
    width: 300px;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .swiper-slide {
    width: 230px;
  }
  .left-arrow:hover,
  .right-arrow:hover {
    box-shadow: none;
  }
}
</style>
