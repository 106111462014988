<template>
  <div id="outerContainer">
    <!-- MODAL -->
    <transition name="modl" v-if="modalVisible">
      <div class="modal">
        <modal-download @close-modal="hideModal"></modal-download></div
    ></transition>
    <div id="nav">
      <the-nav :logoVisible="true" @open-modal="showModal"></the-nav>
    </div>
    <div id="app">
      <div class="container">
        <a href="/"
          ><div class="back-button"><back-button></back-button></div
        ></a>
        <section-headline
          headLine="Privacy Policy"
          className="main-header"
        ></section-headline>
        <div class="content-section">
          <h1>Mr. Right Application Privacy Policy</h1>
          <h2>Introduction</h2>
          <p>
            Welcome. You have arrived at the Mr. Right app (<span class="bold"
              >“App”</span
            >) or services in connection with the App provided by Nametag
            Production, Inc. and/or its designee(s) (“<span class="bold"
              >Company</span
            >,” or “<span class="bold">we</span>,” “<span class="bold">our</span
            >,” or “<span class="bold">us</span>”). We respect your privacy and
            want to protect your personal information.
          </p>
          <p>
            This <span class="bold">Privacy Policy</span> governs the App, and
            goods, services, and events in connection with the App provided by
            Company. This Privacy Policy also applies to information (including
            information that may be considered “personal information” as set
            forth below) that we may collect from you in person at our physical
            location (“<span class="bold">Location</span>”), via phone calls or
            other communications with our personnel or in any other instance
            when you contact us in connection with the App or goods, services,
            or events related thereto, except as set forth in this Privacy
            Policy. We refer to all of the above as our “<span class="bold"
              >Services</span
            >.” For clarity, this Privacy Policy does not govern information
            collected, disclosed, sold, or shared in connection with employment
            matters with Company, if any.
          </p>
          <p>
            As a preliminary matter, it is important to note that if you contact
            us to inquire about our Services, visit our Locations in connection
            with our Services, or otherwise use or interact with our App or
            other Services, you agree to (a) our collection, use, and disclosure
            of your information as part of the Services, as set forth in this
            Privacy Policy, and (b) our
            <a href="/terms-of-use"><span class="bold">Terms of Use</span></a
            >. This Privacy Policy is incorporated into our Terms of Use which
            govern your use of the App and other Services. If you do not agree
            to the terms of this Privacy Policy or the Terms of Use, do not
            access or use our App or other Services. If you have questions about
            this Privacy Policy or the Terms of Use, please contact us at
            <a href="mailto:admin@yourmrright.app">admin@yourmrright.app</a>.
          </p>
          <p>
            In this Privacy Policy, we tell you what information we collect, why
            we collect it, how we use it, under what circumstances we may share
            it with third parties, how we protect that information, and how you
            may opt out of the sharing of that information. Your personal
            information will be shared with members of our Company who need to
            know this information in order for us to provide Services to you. At
            times, we may need to disclose your personal information to third
            parties outside the Company who may be assisting us with providing
            Services to you, as set forth in this Privacy Policy.
          </p>
          <p>
            The Services may be provided through platforms or utilize features
            controlled by third parties, or include links to sites operated by
            third parties. For example, you may access the App through a third
            party’s mobile device platform. In addition, the App may require the
            use of a third-party service to display advertisements or provide
            augmented reality features. This Privacy Policy does not apply to
            any such third-party platform or service or any other features,
            platforms, products, goods, services, websites, applications, or
            content that is provided by any third party (collectively, “<span
              class="bold"
              >Third-Party Services</span
            >”). Third-Party Services are governed by their own respective terms
            of use and privacy policies. Thus, when you visit or interact with
            our Services and utilize any Third-Party Services, the information
            we collect is governed by this Privacy Policy and the information
            such Third-Party Services may collect is subject to the terms of use
            and privacy policies of the applicable third party(ies), which we
            encourage you to read carefully. For clarity, any privacy selections
            you make on Third-Party Services do not apply to our use of
            information that we have collected directly through the Services.
          </p>
        </div>
        <div class="content-section">
          <h2>Table of Contents</h2>
          <ul>
            <li><a href="#whatInfo">What Information We Collect</a></li>
            <li><a href="#whyWe">Why We Collect Information</a></li>
            <li>
              <a href="#howWe">How We Share and Disclose Information</a>
            </li>
            <li><a href="#adsAndInfo">Ads and Information About You</a></li>
            <li>
              <a href="#yourChoices"
                >Your Choices About the Information We Collect</a
              >
            </li>
            <li><a href="#childrensPrivacy">Children’s Privacy</a></li>
            <li><a href="#doNotTrack">Do Not Track Disclosures</a></li>
            <li>
              <a href="#visitorsOutsideUs"
                >Visitors to the Services Outside of the United States</a
              >
            </li>
            <li><a href="#updatingYourInfo">Updating Your Information</a></li>
            <li><a href="#links">Links</a></li>
            <li><a href="#security">Security</a></li>
            <li><a href="#intl">International Data Transfers</a></li>
            <li>
              <a href="#infoEuro"
                >Information for European Union, European Economic Area, and
                United Kingdom Residents</a
              >
            </li>
            <li><a href="#infoCad">Information for Canadian Residents</a></li>
            <li><a href="#caliPriv">Your California Privacy Rights</a></li>

            <li><a href="#nevadaPriv">Your Nevada Privacy Rights</a></li>
            <li>
              <a href="#questions">Questions/Changes in Privacy Policy</a>
            </li>
          </ul>
        </div>
        <div class="content-section">
          <h2 id="whatInfo">What Information We Collect</h2>
          <p>
            As described more fully below, we collect information from you
            directly, automatically when you interact with the Services, and
            from third parties. Some of this information may be publicly
            available and other information may be for your own use as described
            in our
            <a href="/terms-of-use"><span class="bold">Terms of Use</span></a
            >. We may combine the information we collect with the Services with
            information we collect through other services we provide. We may
            also combine information we collect in any offline setting with
            information we collect online. Some of the information we collect
            may be considered “personal information” under various applicable
            laws. We consider information that identifies you as a specific,
            identified individual, such as your name, phone number, and email
            address, to be personal information. We will also treat other
            information, including IP addresses, as “personal information” where
            required by applicable law. We may de-identify your personal
            information so as to make it non-personally identifiable, either by
            combining it with information about other individuals and/or by
            hashing the information or otherwise removing characteristics that
            make the information personally identifiable to you. We will treat
            de-identified information as non-personal to the fullest extent
            allowed by applicable law.
          </p>
          <ul>
            <li>
              Information You Provide to Us
              <ul class="sub-head">
                <li>
                  In connection with the Services we provide, we may ask you to
                  provide and/or you may elect to provide us with certain
                  information, including:
                  <ul>
                    <li>
                      your first and last name; your alias; your contact
                      information, such as email address, home and/or business
                      address, telephone number, or mobile number; geographic
                      information such as your city, biometric information,
                      volumetric data, state, and/or country; username;
                      information regarding who your agent or other
                      representatives are (if any); your birthdate; your service
                      history; projects you are or have been involved with; your
                      picture; written materials, audiovisual content, or other
                      content files; information regarding your preferences or a
                      consumer profile;
                    </li>
                    <li>
                      demographic information, including, without limitation,
                      gender identification, ethnic identification, status,
                      identity, affiliation, interests, or preferences;
                    </li>
                    <li>
                      social media information, such as social media handles,
                      content, and other data shared with us directly or through
                      third-party features that you use on our App and other
                      services (such as apps, tools, widgets, and plug-ins
                      offered by social media services like Facebook, Instagram,
                      LinkedIn, and Twitter) or posted on social media pages
                      (such as our social media page[s], if any, or other pages
                      accessible to us); or
                    </li>
                    <li>
                      other information that could reasonably be used to
                      identify you personally or identify your household or
                      device.
                    </li>
                  </ul>
                  Your decision to provide us with information is voluntary, but
                  if you choose not to provide the requested information, you
                  may not be able to take advantage of all of the App’s features
                  or our Services.
                </li>
              </ul>
            </li>
          </ul>
          <!-- section 2 -->
          <ul>
            <li>
              Information That Is Automatically Collected
              <ul class="sub-head">
                <li>
                  In addition to information that you choose to submit to us, we
                  and/or our service providers may automatically collect and/or
                  store certain information when you visit or interact with the
                  Services (“<span class="bold">Usage Information</span>”). This
                  Usage Information may be stored and/or accessed from your
                  personal computer, laptop, tablet, mobile phone, or other
                  device (each, a “<span class="bold">Device</span>”) whenever
                  you visit or interact with our App or other Services. Usage
                  Information may include:
                  <ul>
                    <li>
                      Your IP address, IDFA, Android/Google Advertising ID,
                      IMEI, or another unique identifier;
                    </li>
                    <li>
                      Your Device functionality (including browser, browser
                      language, operating system, hardware, and/or mobile
                      network information);
                    </li>
                    <li>Referring and exit web pages and URLs;</li>
                    <li>
                      The areas within our App that you visit and your
                      activities there, including remembering you and your
                      preferences;
                    </li>
                    <li>
                      Your Device location or other geolocation information
                      (inferred from IP address), including the city, zip code,
                      region, or country from which you accessed the Services;
                    </li>
                    <li>Your Device characteristics; and</li>
                    <li>
                      Certain other Device data, including the time of day you
                      visit our App.
                    </li>
                  </ul>
                  For location information, we may use this information to
                  provide customized Services, content, and other information
                  that may be of interest to you.<br /><br />
                  If you no longer wish for us or our service providers to
                  collect and use location information from your Device, you may
                  disable the location features on your Device. Consult your
                  Device manufacturer settings for instructions. Please note
                  that if you disable such features, your ability to access
                  certain features, Services, content, promotions, or products
                  may be limited or disabled.
                </li>
              </ul>
            </li>
          </ul>
          <!-- end section 2 -->
          <!-- section 3 -->
          <ul>
            <li>
              Use of Tracking Technologies
              <ul class="sub-head">
                <li>
                  We may use various methods and technologies to store or
                  collect Usage Information (“<span class="bold"
                    >Technologies</span
                  >”). Tracking Technologies may set, change, alter, or modify
                  settings or configurations on your Device. A few of the
                  Tracking Technologies used on the Services may include, but
                  will not be limited to, the following (as well as
                  future-developed tracking technology or methods that are not
                  listed here):

                  <ul>
                    <li>
                      <span class="bold">SDK or Software Development Kit</span>.
                      A SDK or a software development kit is a software
                      component that can allow us or third parties to show you
                      advertisements, provide augmented reality features,
                      collect Usage Information, and/or perform other functions.
                    </li>
                    <li>
                      <span class="bold">Web Beacons</span>. A web beacon is a
                      small tag (which may be invisible to you) that may be
                      placed on our Services’ pages and messages. Web beacons or
                      similar technologies may be used for a number of purposes,
                      including, without limitation, to count visitors to
                      certain Services, to monitor how users navigate the
                      Services, or to count how many particular links were
                      actually viewed.
                    </li>
                    <li>
                      <span class="bold">Embedded Scripts</span>. An embedded
                      script is programming code that is designed to collect
                      information about your interactions with certain Services,
                      such as the links you click on. The code is temporarily
                      downloaded onto your Device from our server or a
                      third-party service provider or business partner, is
                      active only while you are connected to any applicable
                      Services, and is deactivated or deleted thereafter.
                    </li>
                    <li>
                      <span class="bold">ETag or Entity Tag</span>. An Etag or
                      entity tag is a feature of the cache in browsers. It is an
                      opaque identifier assigned by a web server to a specific
                      version of a resource found at a URL.
                    </li>
                    <li>
                      <span class="bold">Browser Fingerprinting</span>.
                      Collection and analysis of information from your Device,
                      such as, without limitation, your operating system,
                      plug-ins, system fonts and other data, for purposes of
                      identification.
                    </li>
                    <li>
                      <span class="bold">Recognition Technologies</span>.
                      Technologies, including application of statistical
                      probability to data sets, which attempt to recognize or
                      make assumptions about users and devices (e.g., that a
                      user of multiple devices is the same user).
                    </li>
                  </ul>
                  We may use Tracking Technologies for a variety of purposes,
                  including:
                  <ul>
                    <li>
                      To allow you to use and access the Services, including for
                      the prevention of fraudulent activity and improved
                      security functionality;
                    </li>
                    <li>
                      To assess the performance of the Services, including as
                      part of our analytic practices or otherwise to improve the
                      content, products, or services offered through the
                      Services;
                    </li>
                    <li>
                      To offer you enhanced functionality when accessing the
                      Services, including identifying you when you sign into our
                      Services or keeping track of your specified preferences or
                      tracking your online activities over time and across
                      third-party sites; and
                    </li>
                    <li>
                      To deliver content relevant to your interests on our
                      Services and thirdparty sites based on how you interact
                      with our content.
                    </li>
                    You can get more information about Tracking Technologies,
                    and instructions on how to opt out of certain tracking
                    technologies, in our “Ads and Information About You” section
                    below.
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              Sources of Personal Information
              <br /><br />
              <ul class="sub-head">
                Additionally, we may obtain personal information from you where
                you expressly provide us with the information. Examples of
                sources from which we collect information include interviews and
                phone calls with you, letters, e-mails or other communications
                from you, information provided via web forms, surveys, or
                inputs/uploads into our site(s) or at events we attend or
                sponsor, and documents you have provided to us.
              </ul>
            </li>
            <li>
              Information We Collect When You Interact with Third-Party Sites or
              Applications

              <br /><br />
              <ul class="sub-head">
                <li>
                  The Services may include functionality that allows certain
                  kinds of interactions between the Services and your account on
                  a third-party website or application. The use of this
                  functionality may involve the third-party site or application
                  providing information to us. For example, we may provide links
                  on the Services to facilitate sending media from the Services
                  or we may use third parties to facilitate emails or postings
                  to social media (like a “Share” or “Forward” button). These
                  third parties may retain any information used or provided in
                  any such communications or activities and these third parties’
                  practices are not subject to our Privacy Policy. We may not
                  control or have access to your communications through these
                  third parties. Further, when you use third-party sites or
                  services, you are using their services and not our services
                  and they, not we, are responsible for their practices. You
                  should read the applicable third-party terms of use and
                  privacy policies before using such third-party tools in
                  connection with our Services. Please also see our
                  <a href="/terms-of-use">Terms of Use</a>
                  for further information.
                </li>
              </ul>
            </li>
            <li>
              Information from Third Parties

              <br /><br />
              <ul class="sub-head">
                <li>
                  We may collect information about you from other sources,
                  including mobile phone carriers and other companies that
                  provide services to you and enable features within the App,
                  phone service providers that allow us to communicate with you
                  over the phone, data analytics providers such as Amplitude,
                  Company affiliates, advertising networks, business partners
                  such as Cellco Partnership d/b/a Verizon Wireless (“Verizon”),
                  App vendors, state or federal agencies, and publicly available
                  sources. We may combine the information we collect from third
                  parties with information that we have collected from you or
                  through your use of the Services, and will apply this Privacy
                  Policy to the combined information.
                </li>
              </ul>
            </li>
          </ul>
          <!-- end section 3 -->
        </div>
        <!--  -->
        <div class="content-section">
          <ul>
            <li>
              Information You Provide to Us
              <ul class="sub-head">
                <li>
                  In connection with the Services we provide, we may ask you to
                  provide and/or you may elect to provide us with certain
                  information, including:
                  <ul>
                    <li>
                      your first and last name; your alias; your contact
                      information, such as email address, home and/or business
                      address, telephone number, or mobile number; geographic
                      information such as your city, state, and/or country;
                      username; information regarding who your agent or other
                      representatives are (if any); your birthdate; your service
                      history; projects you are or have been involved with; your
                      picture; written materials, audiovisual content, or other
                      content files; information regarding your preferences or a
                      consumer profile;
                    </li>
                    <li>
                      demographic information, including, without limitation,
                      gender identification, ethnic identification, status,
                      identity, affiliation, interests, or preferences;
                    </li>
                    <li>
                      social media information, such as social media handles,
                      content, and other data shared with us directly or through
                      third-party features that you use on our App and other
                      services (such as apps, tools, widgets, and plug-ins
                      offered by social media services like Facebook, Instagram,
                      LinkedIn, and Twitter) or posted on social media pages
                      (such as our social media page[s], if any, or other pages
                      accessible to us); or
                    </li>
                    <li>
                      other information that could reasonably be used to
                      identify you personally or identify your household or
                      device.
                    </li>
                  </ul>
                  Your decision to provide us with information is voluntary, but
                  if you choose not to provide the requested information, you
                  may not be able to take advantage of all of the App’s features
                  or our Services.
                </li>
              </ul>
            </li>
          </ul>
          <!-- section 2 -->
          <ul>
            <li>
              Information That Is Automatically Collected
              <ul class="sub-head">
                <li>
                  In addition to information that you choose to submit to us, we
                  and/or our service providers may automatically collect and/or
                  store certain information when you visit or interact with the
                  Services (“Usage Information”). This Usage Information may be
                  stored and/or accessed from your personal computer, laptop,
                  tablet, mobile phone, or other device (each, a “Device”)
                  whenever you visit or interact with our App or other Services.
                  Usage Information may include:
                  <ul>
                    <li>
                      Your IP address, IDFA, Android/Google Advertising ID,
                      IMEI, or another unique identifier;
                    </li>
                    <li>
                      Your Device functionality (including browser, browser
                      language, operating system, hardware, and/or mobile
                      network information);
                    </li>
                    <li>Referring and exit web pages and URLs;</li>
                    <li>
                      The areas within our App that you visit and your
                      activities there, including remembering you and your
                      preferences;
                    </li>
                    <li>
                      Your Device location or other geolocation information
                      (inferred from IP address), including the city, zip code,
                      region, or country from which you accessed the Services;
                    </li>
                    <li>Your Device characteristics; and</li>
                    <li>
                      Certain other Device data, including the time of day you
                      visit our App.
                    </li>
                  </ul>
                  For location information, we may use this information to
                  provide customized Services, content, and other information
                  that may be of interest to you.<br /><br />
                  If you no longer wish for us or our service providers to
                  collect and use location information from your Device, you may
                  disable the location features on your Device. Consult your
                  Device manufacturer settings for instructions. Please note
                  that if you disable such features, your ability to access
                  certain features, Services, content, promotions, or products
                  may be limited or disabled.
                </li>
              </ul>
            </li>
          </ul>
          <!-- end section 2 -->
          <!-- section 3 -->

          <li style="list-style: none">
            <h2 id="whyWe">Why We Collect Information</h2>
            <ul class="sub-head">
              <li>
                We may use information collected from or about you, including
                personal information and Usage Information, for various
                purposes, including:

                <ul>
                  <li>Responding to your requests for information;</li>
                  <li>
                    Allowing you to participate in the Services (and any
                    features or activities that we offer), and providing
                    Services to you, including customer service and
                    entertainment services;
                  </li>
                  <li>Providing the Services to others;</li>
                  <li>
                    Updating and verifying existing personal information we have
                    in our records;
                  </li>
                  <li>Verifying identity and for fraud prevention;</li>
                  <li>
                    Providing updates and information about products and
                    services we provide;
                  </li>
                  <li>
                    Sending you marketing information about Company and our
                    affiliated entities;
                  </li>
                  <li>
                    Sending you email communications such as electronic
                    newsletters about our Services and events and promotions
                    which may be of interest to you;
                  </li>
                  <li>
                    Improving the effectiveness of our Services, our marketing
                    endeavors, and our product and service offerings;
                  </li>
                  <li>
                    Identifying your product and service elections and
                    preferences, providing personalized content and ads and
                    informing you of new or additional information, products,
                    services, and content that may be of interest to you;
                  </li>
                  <li>
                    Helping us address problems with and improve our App and
                    other Services, including testing and creating new products,
                    features, and services;
                  </li>
                  <li>
                    Protecting the security and integrity of the Services,
                    including understanding and resolving any technical and
                    security issues reported on our Services;
                  </li>
                  <li>
                    Conducting analysis, research, and reports regarding the use
                    of our App and other Services;
                  </li>
                  <li>For internal business purposes;</li>
                  <li>
                    Complying with the law and protecting the safety, rights,
                    property, or security of Company, the Services, and the
                    general public; and
                  </li>
                  <li>
                    For other purposes disclosed at the time you provide your
                    information or as otherwise set forth in this Privacy
                    Policy.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <!--  -->
          <li style="list-style: none">
            <h2 id="howWe">How We Share and Disclose Information</h2>

            <ul class="sub-head">
              <li>
                We may aggregate, de-identify, and/or anonymize any information
                collected through the App or other Services such that such
                information is no longer linked to your personally identifiable
                information. We may use and share this aggregated and anonymized
                information (non-personal information) for any purpose,
                including, without limitation, for research and marketing
                purposes, and may also share such data with our affiliates and
                third parties. We may share the information we have collected
                about you as disclosed at the time you provide us with
                information or your consent, and as described in this Privacy
                Policy, including:

                <ul>
                  <li>
                    As Part of the Services. We may disclose your information in
                    ways as disclosed at the time you provide your information
                    and as described in this Privacy Policy.
                  </li>
                  <li>
                    Unaffiliated Third Parties for Direct Marketing Purposes. We
                    do not share your personal information with unaffiliated
                    third parties for their direct marketing purposes without
                    your consent (which may consist of offering you the
                    opportunity to either opt-in or opt-out of such sharing).
                  </li>
                  <li>
                    To Business Partners and Service Providers. We may provide
                    information to business partners like Verizon. We may also
                    provide information to third-party service providers to
                    perform certain services or functions on our behalf, or
                    assist us in providing the Services to you, but only if we
                    have a contractual agreement with the other party which
                    prohibits them from disclosing or using the information
                    other than for the purposes for which it was disclosed.
                    Examples of such disclosures include using a reservations
                    maker, merchandise store, customer service provider, email
                    marketing provider, food delivery service, data analysis
                    provider, or other administrative services.
                  </li>
                  <li>
                    In Connection with Business Transitions. We may disclose
                    your information in connection with a business transition,
                    such as a merger, acquisition, bankruptcy, or sale of all or
                    a portion of our assets, including during the course of any
                    due diligence process, provided that we take appropriate
                    precautions (for example, through a written confidentiality
                    agreement) so the purchaser, merger partner, or other
                    applicable third party does not disclose information
                    obtained in the course of the review. By providing your
                    personal information, you agree that we may transfer such
                    information to the acquiring entity without your further
                    consent.
                  </li>
                  <li>
                    For Administrative and Legal Reasons. We reserve the right
                    to use or disclose any information as needed to satisfy any
                    law, regulation, or legal request; to protect the integrity
                    of the App or other Services; to fulfill your requests; to
                    cooperate in a law enforcement investigation, an
                    investigation on a public safety matter, or an investigation
                    into claims of intellectual property infringement; to
                    protect and defend the legal rights and/or property of
                    Company and any of our officers, directors, or employees,
                    any users, or any other party; or, in an emergency, to
                    protect the health and safety of users or the general
                    public.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <!--  -->
          <li style="list-style: none">
            <h2 id="adsAndInfo">Ads and Information About You</h2>

            <ul class="sub-head">
              <li>
                You may see certain ads on our App or other Services because we
                participate in advertising networks administered by third
                parties. These networks track your online activities over time
                and across third-party websites and online services by
                collecting information through automated means, including
                through the use of the Tracking Technologies described above,
                and they use this information to show you advertisements that
                are tailored to your individual interests. The information they
                collect may include information about your interactions with our
                App and other Services, such as the pages you have viewed. This
                collection and ad targeting takes place both on our Services and
                on third-party websites and/or applications that participate in
                the ad network. This process also helps us track the
                effectiveness of our communications and marketing efforts.
              </li>
            </ul>
          </li>
          <!--  -->
          <li style="list-style: none">
            <h2 id="yourChoices">
              Your Choices About the Information We Collect
            </h2>

            <ul class="sub-head">
              <li>
                If you would like to access, correct, update, suppress,
                restrict, or delete your personal information, object to or opt
                out of the processing of personal information, or withdraw your
                consent (which will not affect the lawfulness of processing
                prior to the withdrawal), you may contact us in accordance with
                the ‘Contact Us’ section below. We will respond to your request
                consistent with applicable law.<br /><br />
                In your request, please make clear what personal information you
                would like to have changed or whether you would like to have
                your personal information suppressed from our database. For your
                protection, we may only implement requests with respect to the
                personal Information associated with the particular identifier
                (e.g., email address, device ID, advertising ID) that you use to
                send us your request, and we may need to verify your identity
                before implementing your request. We will try to comply with
                your request as soon as reasonably practicable.<br /><br />
                If you would prefer that we not share your name and mailing
                address with third parties (other than with our affiliates) to
                receive promotional offers, you have the option to opt out of
                such sharing. To do so, please email us at
                <a href="mailto:admin@yourmrright.app">admin@yourmrright.app</a
                >. Your choice will not affect our ability to share information
                in the other ways described in this Privacy Policy. <br /><br />
                If you do not wish to receive emails about special offers,
                events, and other promotions from us, email us at
                <a href="mailto:admin@yourmrright.app">admin@yourmrright.app</a>
                to opt out or use the opt-out mechanism included in each such
                email. <br /><br />
                If you do not wish to receive other marketing materials by
                regular mail from us, please write us at:<br /><br />
                Nametag Production, Inc.<br />
                3500 West Olive Avenue, Suite 610<br />
                Burbank, CA 91505<br /><br />
                Please note that certain of your personal information, such as
                your name or other identifying information, may remain in our
                database even after a deletion request in order to maintain the
                integrity and historical record of our database and systems, or
                to comply with applicable laws and regulations. We reserve the
                right to send you certain communications relating to your use of
                our Services, service announcements, notices of changes to this
                Privacy Policy, or other terms, or similar administrative or
                transactional messages, and such messages will be unaffected by
                any choice to opt out of marketing communications.
              </li>
            </ul>
          </li>
          <!--  -->
          <li style="list-style: none">
            <h2 id="childrensPrivacy">Children’s Privacy</h2>

            <ul class="sub-head">
              <li>
                The App and other Services are not intended for use by children
                under the age of 18. We do not request, or knowingly collect,
                any personally identifiable information from children under the
                age of 18. If you are the parent or guardian of a child under 18
                who you believe has provided her or his information to us,
                please contact us at
                <a href="mailto:admin@yourmrright.app">admin@yourmrright.app</a>
                to request the deletion of that information.
              </li>
            </ul>
          </li>
          <!--  -->
          <li style="list-style: none">
            <h2 id="doNotTrack">Do Not Track Disclosures</h2>

            <ul class="sub-head">
              <li>
                Do Not Track (“DNT”) is a web browser setting offered by some
                web browsers that requests that a web application disable its
                tracking of an individual user. When you choose to turn on the
                DNT setting in your browser, your browser sends a special signal
                to websites, analytics companies, ad networks, plug in
                providers, and/or other web services you encounter while
                browsing to stop tracking your activity. Various third parties
                are developing or have developed signals or other mechanisms for
                the expression of consumer choice regarding the collection of
                information about an individual consumer’s online activities
                over time and across third-party websites or online services
                (e.g., browser do not track signals), but there is no
                universally-agreed upon standard for what a company should do
                when it detects a DNT signal. Like many websites and online
                services, we may not alter our practices when our systems
                receive these signals or other mechanisms from a visitor’s
                browser, unless specifically required by law. You can learn more
                about Do Not Track at
                <a href="https://www.allaboutdnt.com">www.allaboutdnt.com</a>.
              </li>
              <!--  -->
            </ul>
          </li>
          <!--  -->
          <li style="list-style: none">
            <h2 id="visitorsOutsideUs">
              Visitors to the Services Outside of the United States
            </h2>

            <ul class="sub-head">
              <li>
                If you are virtually visiting the Services from a location
                outside of the U.S., your connection will be through and to
                servers located in the U.S. All information you receive from the
                Services will be created on servers located in the U.S., and all
                information you provide will be maintained on web servers and
                systems located within the U.S. The data protection laws in the
                United States may differ from those of the country in which you
                are located, and your information may be subject to access
                requests from governments, courts, or law enforcement in the
                United States according to laws of the United States. By using
                the Services or providing us with any information, you consent
                to the transfer to, and processing, usage, sharing and storage
                of your information in the United States and in other countries,
                as set forth in this Privacy Policy.
              </li>
              <!--  -->
            </ul>
          </li>
          <!--  -->
          <li style="list-style: none">
            <h2 id="updatingYourInfo">Updating Your Information</h2>

            <ul class="sub-head">
              <li>
                We prefer to keep your personal information accurate and up to
                date. <br /><br />

                If you would like to change your contact information or other
                personal information, please contact us at
                <a href="mailto:admin@yourmrright.app">admin@yourmrright.app</a
                >. When submitting a request, please be sure to include your
                full name, email address, and information sufficient to permit
                us to locate the personal information you are requesting us to
                correct or update. We will make good faith efforts to make
                requested changes in our then active databases as soon as
                reasonably practicable. <br /><br />

                Note that we may retain information that is deleted, updated, or
                otherwise changed for legal, regulatory, administrative, or
                internal business purposes. <br /><br />

                In the event we permit users to publicly post content on any
                Services, you may request that Company remove the content by
                contacting us at admin@yourmrright.app. In some circumstances,
                we may maintain the content but remove any reference to you so
                that the posts are anonymized. When submitting a request, please
                be sure to include your full name, email address, and
                information sufficient to permit us to locate the content you
                are requesting us to remove. We will make such requested changes
                in our active databases as soon as reasonably practicable. Note
                that any removal of public postings in accordance with these
                procedures does not ensure complete or comprehensive removal of
                the content or information you posted, which may persist in
                archival records or may have been copied by third parties while
                publicly accessible.<br /><br />
              </li>
              <!--  -->
            </ul>
          </li>
          <!--  -->
          <li style="list-style: none">
            <h2 id="links">Links</h2>

            <ul class="sub-head">
              <li>
                For your convenience, the Services and this Privacy Policy may
                contain links to other websites. Company is not responsible for
                the privacy practices, advertising, products, services, or
                content of such other websites. None of the links on the
                Services should be deemed to imply that Company endorses or has
                any affiliation with the links. Please see our
                <a href="/terms-of-use">Terms of Use</a> for more information.
              </li>
              <!--  -->
            </ul>
          </li>
          <!--  -->
          <li style="list-style: none">
            <h2 id="security">Security</h2>

            <ul class="sub-head">
              <li>
                We incorporate commercially reasonable safeguards to help
                protect and secure your personal information. However, no system
                can be guaranteed 100% secure. As a result, we cannot guarantee
                or warrant the security of any information you transmit to or
                from our Services, or guarantee that it will be free from
                unauthorized access by third parties. You provide us with
                information at your own initiative and risk. If you have reason
                to believe that your interaction with us is no longer secure,
                please immediately notify us in accordance with the “Contact Us”
                section below.
              </li>
              <!--  -->
            </ul>
          </li>
          <!--  -->
          <li style="list-style: none">
            <h2 id="intl">International Data Transfers</h2>

            <ul class="sub-head">
              <li>
                Because Company works with global companies and technologies, we
                may transfer your personal information outside of the country in
                which it was originally provided. This may include transfers to
                third parties, such as service providers or affiliated entities
                who are located outside the United States or the European Union,
                where data protection laws may not offer the same level of
                protection as those in the U.S., E.U. or European Economic Area
                (“<span class="bold">EEA</span>”). When we transfer personal
                data outside of these areas, we take steps to make sure that
                appropriate safeguards are in place to protect your personal
                information.
              </li>
              <!--  -->
            </ul>
          </li>
          <!--  -->
          <li style="list-style: none">
            <h2 id="infoEuro">
              Information for European Union, European Economic Area, and United
              Kingdom Residents
            </h2>

            <ul class="sub-head">
              <li>
                EU/UK Data Subject Rights <br /><br />
                The GDPR (EU General Data Protection Regulation) provides
                certain rights for EU/EEA residents. Residents of the United
                Kingdom have similar rights under the Data Privacy Act. You can
                access information about you and update or correct it. In some
                cases, you can also ask us to erase it, restrict or stop
                processing, or provide data in a “portable” form, if feasible.
                You may withdraw consent (“opt-out”) if you have previously
                consented to any particular use of your information, such as
                receiving emails or text messages. For all requests, contact
                admin@yourmrright.app. You may also decline to share certain
                personal data with us, in which case we may not be able to
                provide some or all of the features and functionality of our App
                or other Services.<br /><br />
                You may make changes to the personal data collected by us as
                described in the “Updating Your Information” section, above. We
                will make commercially reasonable efforts to provide you with
                reasonable access to your personal data within thirty (30) days
                of your access request to the contact address below. We provide
                this access so that you may review, make corrections, or request
                deletion of your personal data. If we cannot honor your request
                within thirty (30) days, we will inform you when we will be able
                to provide such access. If for some reason access is denied, we
                will provide an explanation as to why access is denied. When
                technically feasible, at your request, we will provide your
                personal data to you or transmit it directly to another
                controller.<br /><br />
                Retention of Information<br /><br />
                We retain your personal data while you continue to use or visit
                any of the Services. We will retain your personal data for as
                long as it is reasonably necessary for the purposes set out in
                this Privacy Policy, considering the time period reasonably
                necessary to: provide the Services to you; exercise the choices
                and rights you have requested; comply with our contractual
                obligations; enforce our terms for use of the Services; and
                comply with legal and regulatory requirements.
                <br /><br />
                Inquiries and Complaints<br /><br />
                Please first contact us as set forth below. If you are an EU/EEA
                or UK resident, you have the right to make a complaint to your
                national data protection supervisory authority if you do not
                believe we have resolved your concern.<br /><br />
                Contact Us<br /><br />
                For all EU/EEA and UK data subject rights requests, please put
                the statement "Your EU/UK Data Subject Rights" in the subject
                field of your request. Please provide your contact information
                in your request and clearly indicate that you are requesting
                information with respect to the Services.<br /><br />
                Email Address:<br />
                admin@yourmrright.app <br /><br />

                Postal Address:<br />
                Nametag Production, Inc.<br />
                3500 West Olive Avenue, Suite 610<br />
                Burbank, CA 91505<br />
              </li>
              <!--  -->
            </ul>
          </li>
          <!--  -->
          <li style="list-style: none">
            <h2 id="infoCad">Information for Canadian Residents</h2>

            <ul class="sub-head">
              <li>
                Canadian residents may have additional rights under Canadian
                law, including the Personal Information Protection and
                Electronic Documents Act (PIPEDA) and successor legislation.
                Please see the website of the Office of the Privacy Commissioner
                of Canada (<a href="https://www.priv.gc.ca">www.priv.gc.ca</a>)
                for further information. <br /><br />
                You and we confirm that it is our wish that this Privacy Policy,
                the Terms of Use, and all other related policies be drawn up in
                English. Vous reconnaissent avoir exigé la rédaction en anglais
                du présent document ainsi que tous les documents qui s'y
                rattachent.
              </li>
              <!--  -->
            </ul>
          </li>
          <!--  -->
          <li style="list-style: none">
            <h2 id="caliPriv">Your California Privacy Rights</h2>

            <ul class="sub-head">
              <li>
                This section of the Privacy Policy applies solely to California
                residents. We adopt this policy to comply with the California
                Consumer Privacy Act of 2018 and any applicable amendments
                thereto (collectively, the “<span class="bold">CCPA</span>”), to
                the extent that the same may apply to us. Except to the extent
                the context otherwise requires, any terms defined in the CCPA or
                its regulations and undefined herein shall have the same defined
                meanings when used in this section.
                <span class="bold">Personal Information We Collect: </span
                ><br /><br />
                Company collects certain types of information that identifies,
                relates to, describes, references, is reasonably capable of
                being associated with, or could reasonably be linked, directly
                or indirectly, with a particular consumer, household, or device
                (“<span class="bold">Personal Information</span>”). In
                particular, we may have collected the following categories of
                Personal Information from our users within the last twelve (12)
                months:
                <ul>
                  <li>
                    Identifiers: such as a real name, alias, IP address or other
                    online identifier, email address, Device identifier, or
                    other similar identifiers
                  </li>
                  <li>
                    Personal information described in the California Customer
                    Records statute (Cal. Civ. Code § 1798.80(e)): such as a
                    name, address, or telephone number
                  </li>
                  <li>
                    Protected classification characteristics under California or
                    federal law: such as sex or marital status, which users
                    elected to disclose in optional research surveys (if any)
                  </li>
                  <li>
                    Commercial information: such as products or services
                    considered or consuming histories or tendencies
                  </li>
                  <li>
                    Internet or other similar network activity: such as users’
                    interactions with the App
                  </li>
                  <li>
                    Geolocation data: precise geolocation data is not collected;
                    however, general location information can be determined from
                    users’ IP addresses and from information users elect to
                    provide
                  </li>
                  <li>
                    Biometric information: such as scans of hand and face
                    geometry
                  </li>
                  <li>Volumetric data: such as a 2D projection</li>
                  <li>
                    Audio, electronic, visual, thermal, olfactory, or similar
                    information: such as recordings of customer service calls or
                    media sent to us by users
                  </li>
                  <li>
                    Inferences drawn from other personal information: such as
                    users’ preferences and behaviors
                  </li>
                </ul>
                <span class="bold">Sources of Personal Information: </span>
                We explain sources from which we collect yours and other
                consumers’ Personal Information in the “What Information We
                Collect” section above. For added convenience, please see the
                sources below:<br /><br />
                <ul>
                  <li>
                    You: We collect Personal Information that you directly
                    provide, whether by email, telephone, surveys, or other
                    means, and that you passively provide through your
                    interactions with our Services.
                  </li>
                  <li>
                    Third-party service providers and business partners: These
                    third-party service providers and business partners may
                    include mobile phone carriers and other companies that
                    provide services to you and enable features in the App;
                    phone service providers that allow us to communicate with
                    you over the phone; data analytics providers, such as
                    Amplitude; Company affiliates; advertising networks; social
                    networks; business partners, such as Verizon; and App
                    vendors.
                  </li>
                  <li>
                    Other sources: These sources may include publicly available
                    sources; analytics tools and other tracking technologies;
                    ourselves; and state or federal agencies.
                  </li>
                </ul>
                <span class="bold">Purposes of Collection or Disclosure: </span
                ><br /><br />
                The business or commercial purpose for collecting or disclosing
                yours and other consumers’ Personal Information is described in
                detail in the “Why We Collect Information” and the “How We Share
                and Disclose Information” sections above. For example, we
                collect or disclose Personal Information:
                <br /><br />
                <ul>
                  <li>To allow you and others to receive the Services</li>
                  <li>
                    To help us improve the Services and to serve you better
                  </li>
                  <li>
                    For legal, regulatory, administrative, and internal business
                    purposes
                  </li>
                  <li>
                    To protect against misuse of the Services, fraud, or
                    criminal activity
                  </li>
                  <li>
                    To detect and troubleshoot problems, resolve disputes, and
                    enforce applicable agreements and policies for the Services
                  </li>
                  <li>
                    For other purposes described to you at the time of
                    collection or otherwise consistent with this Privacy Policy
                  </li>
                </ul>
                <span class="bold"
                  >Personal Information Disclosed for a Business Purpose: </span
                ><br /><br />
                We may have disclosed the following categories of Personal
                Information about consumers for a business purpose within the
                last twelve (12) months:

                <br /><br />
                <ul>
                  <li>
                    Identifiers: such as a real name, alias, IP address or other
                    online identifier, email address, Device identifier, or
                    other similar identifiers
                  </li>
                  <li>
                    Personal information described in the California Customer
                    Records statute (Cal. Civ. Code § 1798.80(e)): such as a
                    name, address, or telephone number
                  </li>
                  <li>
                    Protected classification characteristics under California or
                    federal law: such as sex or marital status, which users
                    elected to disclose in optional research surveys (if any)
                  </li>
                  <li>
                    Commercial information: such as products or services
                    considered or consuming histories or tendencies
                  </li>
                  <li>
                    Internet or other similar network activity: such as users’
                    interactions with the App
                  </li>
                  <li>
                    Geolocation data: precise geolocation data is not collected;
                    however, general location information can be determined from
                    users’ IP addresses and from information users elect to
                    provide
                  </li>
                  <li>
                    Biometric information: such as scans of hand and face
                    geometry
                  </li>
                  <li>Volumetric data: such as a 2D projection</li>
                  <li>
                    Audio, electronic, visual, thermal, olfactory, or similar
                    information: such as recordings of customer service calls or
                    media sent to us by users
                  </li>
                  <li>
                    Inferences drawn from other personal information: such as
                    users’ preferences and behaviors
                  </li>
                </ul>
                <br /><br />
                We may have disclosed each of these categories of Personal
                Information to the following categories of third parties:<br /><br />
                <ul>
                  <li>
                    Business partners and service providers, as described in the
                    “How We Share and Disclose Information” section above
                  </li>

                  <li>Advertising partners and services</li>
                  <li>
                    Third parties who may acquire your information in connection
                    with a merger, acquisition, or other ownership transition
                  </li>
                  <li>
                    Third parties or affiliated companies when you request that
                    we share your information with them
                  </li>
                  <li>
                    Other third parties (including government entities) to
                    comply with laws, regulations, or legal requests or to
                    protect or defend our rights or the rights of any third
                    party
                  </li>
                </ul>
                <br /><br />
                Under California law, if you are a resident of California, you
                may have the right to (a) request that we disclose to you what
                Personal Information we collect, use, disclose, and sell,
                including (i) the right to request that we provide to you the
                categories and specific pieces of Personal Information we have
                collected about you in the prior twelve (12) months, (ii) the
                categories of Personal Information that we have sold or shared
                about you and the categories of third parties to whom such
                Personal Information was sold or shared, by category or
                categories of Personal Information for each category of third
                parties to whom such Personal Information was sold or shared, in
                the prior twelve (12) months, and (iii) the categories of
                Personal Information that we have disclosed about you for a
                business purpose and the categories of persons to whom it was
                disclosed for a business purpose in the prior twelve (12)
                months, subject to the terms of the CCPA (collectively, the
                “<span class="bold">Right to Know</span>”); (b) request that we
                correct inaccurate Personal Information that we hold about you;
                (c) request that we delete the Personal Information we collect
                about you (“<span class="bold">Right to Delete</span>”), subject
                to exceptions set forth in the CCPA; and (d) opt-out from the
                sale or sharing of your Personal Information as further set
                forth in the CCPA. These rights are subject to some limitations,
                such as when we are retaining data to comply with our own legal
                obligations. At this time, we do not sell Personal Information
                to third parties and we do not share Personal Information with
                third parties for cross-context behavioral advertising, subject
                to any applicable exceptions set forth in the CCPA.<br /><br />
                Please note that if we collected information about you for a
                single one-time transaction and do not keep that information in
                the ordinary course of business, that information will not be
                retained for purposes of a request under this section. In
                addition, if we have de-identified or anonymized data about you,
                we are not required to re-identify or otherwise link your
                identity to that data if it is not otherwise maintained that way
                in our records. We retain each applicable category of Personal
                Information we have collected about you while you continue to
                use or visit any of the Services. We will retain Personal
                Information we have collected about you for as long as it is
                reasonably necessary for the purposes set out in this Privacy
                Policy, considering the time period reasonably necessary to:
                provide the Services to you; exercise the choices and rights you
                have requested; comply with our contractual obligations; enforce
                our terms for use of the Services; and comply with legal and
                regulatory requirements.<br /><br />
                We will not discriminate against you for exercising any of your
                CCPA rights. Unless permitted by the CCPA, we will not:<br /><br />
                <ul>
                  <li>Deny you goods or services.</li>
                  <li>
                    Charge you different prices or rates for goods or services,
                    including through granting discounts or other benefits, or
                    imposing penalties.
                  </li>
                  <li>
                    Provide you with a different level or quality of goods or
                    services.
                  </li>
                  <li>
                    Suggest that you may receive a different price or rate for
                    goods or services or a different level or quality of goods
                    or services.
                  </li>
                </ul>
                <br /><br />
                However, we may offer you certain financial incentives permitted
                by the CCPA that can result in different prices, rates, or
                quality levels. Any CCPA-permitted financial incentive we offer
                will reasonably relate to your Personal Information’s value.
                Participation in a financial incentive program requires your
                prior opt-in consent, which you may revoke at any time.<br /><br />You
                may make a request to us by contacting us by email, postal mail,
                or telephone as described below. We may require you to confirm
                your identity and your residency in order to obtain the
                information, and you are entitled to make this request only
                twice per year. Please include “California Privacy Rights” as
                the subject line of your communication. You must include your
                full name and email address, and attest to the fact that you are
                a California resident by including a California postal address
                in your request. We will acknowledge your request within ten
                (10) days and respond to your request within forty-five (45)
                days or let you know if we need additional time. <br /><br />
                <span class="bold">Email Address:</span><br />
                <a href="mailto:admin@yourmrright.app">admin@yourmrright.app</a
                ><br /><br />
                <span class="bold">Postal Address:</span><br />
                Nametag Production, Inc.<br />
                3500 West Olive Avenue, Suite 610<br />
                Burbank, CA 91505<br />
                <br />
                Toll-Free Number: (888) 467-7444<br />
                (888) 4MRRIGH<br /><br />
                <span class="bold"
                  >Personal Information Disclosed for a Business Purpose: </span
                ><br /><br />
                Pursuant to California Civil Code Section 1798.83, residents of
                the State of California have the right to request from companies
                conducting business in California certain information relating
                to third parties to which the companies have disclosed certain
                categories of personal information during the preceding year for
                the third parties’ direct marketing purposes. Alternatively, the
                law provides that a company may comply, as Company does, by
                disclosing in its privacy policy that it provides consumers
                choice (opt-out or opt-in) regarding sharing personal
                information with third parties for those third parties’ direct
                marketing purposes, and information on how to exercise that
                choice. As stated in this Privacy Policy, Company provides you
                choice prior to sharing your personal information with third
                parties for their direct marketing purposes. If you do not
                opt-in or if you opt-out at the time we offer that choice, we do
                not share your information with that identified third party for
                its direct marketing purposes.<br /><br />
                If you are a California resident and you have questions about
                our practices with respect to sharing information with third
                parties for their direct marketing purposes and your ability to
                exercise choice, please email us at
                <a href="mailto:admin@yourmrright.app">admin@yourmrright.app</a>
                or write to us at the following mailing address: Nametag
                Production, Inc., 3500 West Olive Avenue, Suite 610, Burbank, CA
                91505. You must put the statement “Your California Privacy
                Rights” in the subject field of your e-mail or include it in
                your writing if you choose to write to us at the designated
                mailing address. You must include your name, street address,
                city, state, and zip code. We are not responsible for notices
                that are not labeled or sent properly, or do not have complete
                information.
              </li>
              <!--  -->
            </ul>
          </li>
          <!--  -->
          <li style="list-style: none">
            <h2 id="nevadaPriv">Your Nevada Privacy Rights</h2>

            <ul class="sub-head">
              <li>
                Pursuant to Nevada law, you have the right to opt-out of the
                sale of certain covered personally identifiable information we
                have collected about you through our websites and online
                services, as those terms are defined under applicable Nevada
                law. We do not currently sell your covered information as those
                terms are defined under applicable Nevada law. However, you may
                still submit an opt-out request and we will honor that request
                as required by Nevada law if we were to ever engage in such a
                sale in the future. If you are a Nevada resident and would like
                to opt-out of future sales of your covered information, please
                submit your request to
                <a href="mailto:admin@yourmrright.app">admin@yourmrright.app</a
                >. Your request must include your full name, street address,
                city, state, zip code, and an email address so that we can
                contact if needed regarding this request. You may also be
                required to take reasonable steps as we determine from time to
                time in order to verify your identity and/or the authenticity of
                the request.
              </li>
              <!--  -->
            </ul>
          </li>
          <!--  -->
          <li style="list-style: none">
            <h2 id="questions">Questions/Changes in Privacy Policy</h2>

            <ul class="sub-head">
              <li>
                If you have questions or concerns with respect to our Privacy
                Policy, you may contact us at
                <a href="mailto:admin@yourmrright.app">admin@yourmrright.app</a>
                or at:<br /><br />
                Nametag Production, Inc.<br />
                3500 West Olive Avenue, Suite 610<br />
                Burbank, CA 91505
                <br /><br />
                To the extent permitted by applicable law, we reserve the right
                to change or amend our Privacy Policy in our discretion at any
                time. We will notify you of material changes by posting the
                changed or modified Privacy Policy on our App and other
                Services, as applicable. We may also provide notice to you in
                other ways, such as through the contact information you
                provided. Any changes will be effective immediately upon the
                posting of the revised Privacy Policy, unless otherwise
                specified. Your continued use of the Services after the
                effective date of the revised Privacy Policy (or such other act
                as specified in the revised Privacy Policy) will, to the fullest
                extent permitted by applicable law, constitute your consent to
                those changes. However, we will provide notice and/or obtain
                your consent (opt-in or opt-out) if required by law. Note that
                you may need to consent to our new policies in order to continue
                participating in the Services. If you are concerned about how
                your personal information is used, please visit our App and
                other Services often for this and other important announcements
                and updates.
              </li>
              <!--  -->
            </ul>
          </li>
          <!-- end section 3 -->
        </div>
        <!--  -->
      </div>
    </div>
    <div id="footerArea">
      <the-footer></the-footer>
    </div>
  </div>
</template>

<script>
import TheNav from "@/components/TheNav.vue";
import TheFooter from "@/components/TheFooter.vue";
import SectionHeadline from "@/components/UI/SectionHeadline.vue";
import ModalDownload from "@/components/ModalDownload.vue";
import BackButton from "@/components/UI/BackButton.vue";

import "@/styles/index.scss";

export default {
  data() {
    return {
      logoVisible: true,
      modalVisible: false,
    };
  },
  name: "PrivacyPage",
  components: {
    TheNav,
    TheFooter,
    SectionHeadline,
    ModalDownload,
    BackButton,
  },
  metaInfo: {
    title: "Privacy Policy | ",
  },
  methods: {
    showModal() {
      this.modalVisible = true;
      const bod = document.querySelector("body");
      bod.style.overflow = "hidden";
    },
    hideModal() {
      this.modalVisible = false;
      const bod = document.querySelector("body");
      bod.style.overflow = "visible";
    },
  },
};
</script>

<style scoped>
.back-button {
  margin-bottom: 60px;
}
.modal {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  overflow: hidden;
  z-index: 1001;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  margin-top: 200px;
  text-align: left;
  width: 80%;
  max-width: 1000px;
  margin: 200px auto;
}

#outerContainer {
  background: #000;
  width: 100%;
  overflow: hidden;
}
#app {
  margin: 0 auto;
  text-align: center;
  color: #2c3e50;
  width: 100%;
}

h1,
h2,
h3,
p,
ul {
  color: #fff;
}

h1 {
  font-size: 80px;
  margin: 100px 0 20px;
}

h2 {
  font-size: 36px;
  margin: 40px 0px;
  line-height: 40px;
}

h3 {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.08em;
}

p {
  margin: 30px 0px;
  font-size: 16px;
  line-height: 20px;
}

ul {
  margin: 0 40px;
  list-style: disc;
}

ul.inner-list li {
  list-style: none;
}

ul.sub-head {
  list-style: none;
}

li {
  margin: 10px;
  line-height: 18px;
}

span.bold {
  font-weight: bold;
}
a {
  text-decoration: underline;
  color: #bdf771;
}
a:hover {
  animation: mouseHover 0.3s linear forwards;
}

@keyframes mouseHover {
  0% {
    cursor: url("../assets/images/ui/cursor-01.png") 50 50, auto;
  }
  8% {
    cursor: url("../assets/images/ui/cursor-02.png") 50 50, auto;
  }
  16% {
    cursor: url("../assets/images/ui/cursor-03.png") 50 50, auto;
  }
  24% {
    cursor: url("../assets/images/ui/cursor-04.png") 50 50, auto;
  }
  33% {
    cursor: url("../assets/images/ui/cursor-05.png") 50 50, auto;
  }
  41% {
    cursor: url("../assets/images/ui/cursor-06.png") 50 50, auto;
  }
  48% {
    cursor: url("../assets/images/ui/cursor-07.png") 50 50, auto;
  }
  56% {
    cursor: url("../assets/images/ui/cursor-08.png") 50 50, auto;
  }
  67% {
    cursor: url("../assets/images/ui/cursor-09.png") 50 50, auto;
  }
  77% {
    cursor: url("../assets/images/ui/cursor-10.png") 50 50, auto;
  }
  85% {
    cursor: url("../assets/images/ui/cursor-11.png") 50 50, auto;
  }
  100% {
    cursor: url("../assets/images/ui/cursor-12.png") 50 50, auto;
  }
}
@media screen and (max-width: 820px) {
  #nav {
    z-index: 5000;
    position: absolute;
  }

  .container {
    margin-top: 50px;
    width: 90%;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 24px;
    line-height: 26px;
  }
  ul {
    margin: 0 10px;
    list-style: disc;
  }
  p {
    font-size: 16px;
  }

  h3 {
    font-size: 16px;
  }

  #outerContainer {
    width: 100%;
  }

  #header {
    margin-top: 50px;
  }
  a:hover,
  a:active {
    animation: none;
  }
}

@media screen and (max-width: 600px) {
}

@media screen and (max-width: 500px) {
  h3 {
    font-size: 15px;
  }
}

@media screen and (max-width: 500px) {
}

@media screen and (max-width: 900px) and (orientation: landscape) {
}
</style>
