<template>
  <div id="outerContainer">
    <!-- MODAL -->
    <transition name="modl" v-if="modalVisible">
      <div class="modal">
        <modal-download @close-modal="hideModal"></modal-download></div
    ></transition>
    <div id="nav">
      <the-nav :logoVisible="logoVisible" @open-modal="showModal"></the-nav>
    </div>
    <div id="app">
      <!-- HEADER AREA -->
      <div id="header">
        <header-area @open-modal="showModal"></header-area>
      </div>
      <!-- TEXT RIBBON -->
      <div id="textRibbon"></div>
      <!-- ICON TEXT AREA -->
      <div id="iconText">
        <icon-text-area></icon-text-area>
      </div>
      <!-- CAST SLIDER -->
      <div id="castArea">
        <section-headline
          headLine="Cast"
          className="cast-members"
        ></section-headline>
        <cast-carousel></cast-carousel>
      </div>
      <!-- TRAILER -->
      <div id="movieTrailer">
        <movie-trailer></movie-trailer>
      </div>
      <pre-footer></pre-footer>
      <!-- FOOTER AREA -->
    </div>
    <div id="footerArea">
      <the-footer></the-footer>
    </div>
  </div>
</template>

<script>
import TheNav from "@/components/TheNav.vue";
import HeaderArea from "@/components/HeaderArea.vue";
import ModalDownload from "@/components/ModalDownload.vue";
import IconTextArea from "@/components/IconTextArea.vue";
import CastCarousel from "@/components/CastCarousel.vue";
import SectionHeadline from "@/components/UI/SectionHeadline.vue";
import MovieTrailer from "@/components/MovieTrailer.vue";
import PreFooter from "@/components/PreFooter.vue";
import TheFooter from "@/components/TheFooter.vue";
import "@/styles/index.scss";

function isInViewport(el) {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export default {
  data() {
    return {
      modalVisible: false,
      logoVisible: false,
    };
  },
  name: "App",
  components: {
    TheNav,
    HeaderArea,
    ModalDownload,
    IconTextArea,
    CastCarousel,
    SectionHeadline,
    MovieTrailer,
    PreFooter,
    TheFooter,
  },
  methods: {
    showModal() {
      this.modalVisible = true;
      const bod = document.querySelector("body");
      bod.style.overflow = "hidden";
    },
    hideModal() {
      this.modalVisible = false;
      const bod = document.querySelector("body");
      bod.style.overflow = "visible";
    },
  },
  mounted() {
    const theLogo = document.querySelector("#mainLogo");
    let that = this;
    document.addEventListener("scroll", function () {
      that.logoVisible = !isInViewport(theLogo);
    });
  },
  unmounted() {
    document.removeEventListener("scroll");
  },
};
</script>

<style scoped>
#outerContainer {
  width: 100%;
  overflow: hidden;
}
#app {
  margin: 0 auto;
  text-align: center;
  color: #2c3e50;
  width: 100%;
}

h3 {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.08em;
}

#header {
  position: relative;
  margin-top: 150px;
  z-index: 1;
}

#textRibbon {
  position: relative;
  width: 100%;
  height: 435px;
  z-index: 0;
  margin-top: -150px;
  background: url("@/assets/images/ribbon.png");
  background-repeat: repeat-x;
  background-position: 0% bottom;
  animation: animatedBackground 10s linear infinite;
}

@keyframes animatedBackground {
  0% {
    background-position: 0px bottom;
  }
  100% {
    background-position: -1460px bottom;
  }
}

#iconText {
  position: relative;
  margin: 60px 0px;
}
#castArea {
  overflow: visible;
  position: relative;
  width: 100%;
}

#castArea::-webkit-scrollbar {
  display: none;
}

#castArea {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#castCards {
  display: flex;
  max-width: 1200px;
  padding: 50px 50px;
}

#movieTrailer {
  position: relative;
  margin: 80px 0px;
}

.modal {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  overflow: hidden;
  z-index: 1001;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modl-enter {
  opacity: 0;
}

.modl-enter-active {
  transition: all 10.5s ease-out;
}

.modl-enter-to {
  opacity: 1;
}

.modl-leave-from {
  opacity: 1;
}

.modl-leave-active {
  transition: all 0.5s ease-in;
}

.modl-leave-to {
  opacity: 0;
}

@media screen and (max-width: 820px) {
  #nav {
    /* display: none; */
    z-index: 5000;
  }

  h3 {
    font-size: 16px;
  }

  #textRibbon {
    height: 209px;
    margin-top: -150px;
    background: url("@/assets/images/ribbon-mobile.png");
    animation: animatedBackground 5.5s linear infinite;
  }

  @keyframes animatedBackground {
    0% {
      background-position: 0px bottom;
    }
    100% {
      background-position: -700px bottom;
    }
  }

  #outerContainer {
    width: 100%;
  }

  #header {
    margin-top: 50px;
  }
  #castCards {
    padding: 0px 0px;
  }
}

@media screen and (max-width: 600px) {
  #header {
    margin-top: 0px;
  }
}

@media screen and (max-width: 500px) {
  h3 {
    font-size: 15px;
  }
  #textRibbon {
    height: 209px;
    margin-top: -100px;
  }
}

@media screen and (max-width: 500px) {
  #textRibbon {
    margin-top: -50px;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  #header {
    margin-top: 20px;
  }
}
</style>
