import { render, staticRenderFns } from "./IGNavLogoHover.vue?vue&type=template&id=308bd3c0&scoped=true&"
var script = {}
import style0 from "./IGNavLogoHover.vue?vue&type=style&index=0&id=308bd3c0&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "308bd3c0",
  null
  
)

export default component.exports