<template>
  <div class="container" @mousemove="mouseMove">
    <div class="inner">
      <div class="content">
        <div class="parallax-icons">
          <!-- item -->
          <div
            class="parallax-item item item-1"
            data-speed-x="-80"
            data-speed-y="-80"
          >
            <div class="inner-item">
              <svg-icon icoType="lightning"></svg-icon>
            </div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-2"
            data-speed-x="-80"
            data-speed-y="-80"
          >
            <div class="inner-item">
              <svg-icon icoType="yinyang"></svg-icon>
            </div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-3"
            data-speed-x="-60"
            data-speed-y="-60"
          >
            <div class="inner-item">
              <svg-icon icoType="dice"></svg-icon>
            </div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-4"
            data-speed-x="-60"
            data-speed-y="-60"
          >
            <div class="inner-item">
              <svg-icon icoType="star"></svg-icon>
            </div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-5"
            data-speed-x="-80"
            data-speed-y="-80"
          >
            <div class="inner-item">
              <svg-icon icoType="hearteyes"></svg-icon>
            </div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-6"
            data-speed-x="-90"
            data-speed-y="-90"
          >
            <div class="inner-item">
              <svg-icon icoType="bitheart"></svg-icon>
            </div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-7"
            data-speed-x="-60"
            data-speed-y="-60"
          >
            <div class="inner-item">
              <svg-icon icoType="star"></svg-icon>
            </div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-8"
            data-speed-x="-80"
            data-speed-y="-80"
          >
            <div class="inner-item">
              <svg-icon icoType="daisy"></svg-icon>
            </div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-9"
            data-speed-x="-50"
            data-speed-y="-50"
          >
            <div class="inner-item">
              <svg-icon icoType="star"></svg-icon>
            </div>
          </div>
        </div>
        <h2>The Perfect Man is a Download Away</h2>
        <div id="mainLogo" ref="mainLogo"><main-logo></main-logo></div>
        <div class="logo-block">
          <div class="logo-text">
            <div>
              <div>An interactive film by</div>
              <div>
                <img src="@/assets/images/pk-logo.png" class="pk-logo" />
              </div>
            </div>
            <div>
              <div>powered by</div>
              <div>
                <img src="@/assets/images/ver-logo.png" class="ver-logo" />
              </div>
            </div>
          </div>
        </div>

        <div v-if="!isMobile" @click="openModal" class="qr-code">
          <QRCode />
        </div>

        <mobile-links v-else></mobile-links>

        <the-phones></the-phones>
      </div>
    </div>
  </div>
</template>

<script>
function doParallaxEffect(event) {
  if (window.innerWidth > 1366) {
    const parallaxContainer = document.querySelector(".parallax-icons");
    const parallaxItems = document.querySelectorAll(".parallax-item");
    const fixer = 0.002;
    const pageX =
      event.pageX - parallaxContainer.getBoundingClientRect().width * 0.5;
    const pageY =
      event.pageY - parallaxContainer.getBoundingClientRect().height * 0.5;
    for (let i = 0; i < parallaxItems.length; i++) {
      let item = parallaxItems[i];
      let speedX = item.getAttribute("data-speed-x");
      let speedY = item.getAttribute("data-speed-y");
      gsap.to(item, {
        x: (item.offsetLeft + pageX * speedX) * fixer,
        y: (item.offsetTop + pageY * speedY) * fixer,
      });
    }
  }
}

// Modules.
import gsap from "gsap";

import MainLogo from "./svg/MainLogo.vue";
import QRCode from "./svg/QRCode.vue";
import ThePhones from "./ThePhones.vue";
import MobileLinks from "../components/MobileLinks.vue";
import SvgIcon from "../components/svg/SvgIcon.vue";

import { isMobileDevice } from "@/assets/js/utils";

export default {
  data() {
    return {
      test: "test",
    };
  },
  name: "HeaderArea",
  components: {
    MainLogo,
    QRCode,
    MobileLinks,
    ThePhones,
    SvgIcon,
  },
  emits: ["open-modal"],
  computed: {
    isMobile() {
      return isMobileDevice();
    },
  },
  methods: {
    openModal() {
      this.$emit("open-modal");
    },
    mouseMove(event) {
      doParallaxEffect(event);
    },
  },
};
</script>

<style scoped>
.container {
  background: radial-gradient(
    50% 50% at 50% 50%,
    #f97ea2 0%,
    rgba(249, 126, 162, 0) 100%
  );
  margin: 80px 0px;
}

.content {
  width: 100%;
  height: 100%;
}

.parallax-icons {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: -120px;
}

.parallax-item {
  position: absolute;
  width: 200px;
  height: 200px;
}

.inner-item {
  animation: animatedBackground 3s ease-in-out infinite;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-1 {
  top: 50px;
  left: 20px;
}

.item-2 {
  top: 400px;
  left: 0px;
}

.item-3 {
  top: 300px;
  left: 200px;
}

.item-4 {
  top: 600px;
  left: 180px;
}

.item-5 {
  top: 60px;
  right: 20px;
}

.item-6 {
  top: 200px;
  right: 120px;
}

.item-7 {
  top: 350px;
  right: 200px;
  transform: scale(1.1);
}

.item-8 {
  top: 420px;
  right: 100px;
}

.item-9 {
  top: 620px;
  right: 90px;
  transform: scale(0.9);
}

@keyframes animatedBackground {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  50% {
    transform: translate(0px, 20px) scale(1.1);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

h2 {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  margin-bottom: 20px;
  color: #000;
}

h3,
.logo-block {
  font-size: 18px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.logo-text {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.logo-text div {
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}

.pk-logo {
  width: 140px;
}
.ver-logo {
  width: 112px;
}
svg {
  margin-top: -2px;
}

.inner {
  position: relative;
  padding: 0;
  width: 90%;
  margin: 0 auto;
}

.logo-block {
  display: flex;
  margin-top: 20px;
  margin-bottom: 50px;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 900px) {
  .parallax-icons {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin: 50px 0px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      #f97ea2 0%,
      rgba(249, 126, 162, 0) 100%
    );
  }
  .logo-text {
    flex-direction: column;
  }
  .logo-text div {
    margin: 1px 0px;
  }
  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 16px;
  }
}

@media screen and (max-width: 600px) {
  .logo-block {
    flex-direction: column;
    margin-bottom: 0px 10px;
  }
}

@media screen and (max-width: 450px) {
  h2 {
    font-size: 14px;
  }
  h3 {
    font-size: 14px;
  }
}

@media screen and (max-width: 350px) {
  h2 {
    font-size: 13px;
  }
  h3 {
    font-size: 13px;
  }
}

@media screen and (max-width: 320px) {
  h2 {
    font-size: 12px;
  }
  h3 {
    font-size: 12px;
  }
}

@media screen and (max-width: 600px) and (orientation: landscape) {
  .parallax-icons {
    display: none;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .container {
    margin: 80px 0px;
  }
}

@media screen and (max-width: 768px) {
  .qr-code {
    margin-top: 25px;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
}
</style>
