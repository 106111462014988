/* eslint-disable */
import Bowser from "bowser";

const getDeviceType = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);

  let deviceType;
  if ( browser.getOSName().toLowerCase() === "android" ) {
    deviceType = "android";
  }
  else if ( browser.getOSName().toLowerCase() === "ios" ) {
    deviceType = "ios";
  }

  return deviceType;
};

const isMobileDevice = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);

  return browser.getPlatformType().toLowerCase() == 'mobile';
};

export { getDeviceType, isMobileDevice };
