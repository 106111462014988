<template>
  <div>
    <div
      class="vimeo-player"
      ref="vid"
      @mouseleave="mouseLeave"
      @mouseenter="mouseEnter"
      @mousemove="mouseEnter"
    >
      <video-controls
        @play-started="playVideo"
        @play-paused="pauseVideo"
        @fullscreen-requested="fullScreen"
        @mute-video="muteVolume"
        @unmute-video="unmuteVolume"
        :percentPlayed="percentPlayed"
        :timeStamp="timeStamp"
        :showControls="controlsVisible"
      ></video-controls>
    </div>
  </div>
</template>

<script>
function formatTimeStamp(seconds) {
  var h = ~~(seconds / 3600);
  var m = ~~((seconds % 3600) / 60);
  var s = ~~seconds % 60;
  var timestamp = "";
  if (h > 0) {
    timestamp += "" + h + ":" + (m < 10 ? "0" : "");
  }
  timestamp += "" + m + ":" + (s < 10 ? "0" : "");
  timestamp += "" + s;
  return timestamp;
}

import Player from "@vimeo/player";
import VideoControls from "./VideoControls.vue";
export default {
  data() {
    return {
      percentPlayed: 0,
      timeStamp: "0:00",
      vidIsPlaying: false,
      controlsVisible: true,
    };
  },
  name: "VimeoPlayer",
  components: { VideoControls },
  methods: {
    initPlayer() {
      const url =
        "https://player.vimeo.com/video/781583076?h=8aa777a7c5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479";
      const options = {
        url,
        width: "100%",
        height: "100%",
        controls: false,
        responsive: true,
        loop: false,
        allowfullscreen: true,
      };
      this.player = new Player(this.$refs.vid, options);
      this.player.pause();
      let that = this;
      this.player.on("timeupdate", function (data) {
        that.percentPlayed = data.percent;
        that.timeStamp = formatTimeStamp(data.seconds);
      });
    },
    playVideo() {
      this.player
        .play()
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
      this.vidIsPlaying = true;
      this.hideControls();
    },
    pauseVideo() {
      this.player
        .pause()
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
      this.vidIsPlaying = false;
    },
    fullScreen() {
      this.player
        .requestFullscreen()
        .then(() => {})
        .catch((error) => {
          console.log(error);
        });
    },
    muteVolume() {
      this.player.setVolume(0);
    },
    unmuteVolume() {
      this.player.setVolume(1);
    },
    hideControls() {
      if (this.vidIsPlaying) {
        setTimeout(() => {
          this.controlsVisible = false;
        }, 2000);
      }
    },
    mouseEnter() {
      setTimeout(() => {
        this.controlsVisible = true;
      }, 200);
    },
    mouseLeave() {
      setTimeout(() => {
        this.controlsVisible = false;
      }, 200);
    },
  },
  mounted() {
    this.initPlayer();
  },
};
</script>

<style>
.vimeo-player {
  position: relative;
}
iframe {
  background: black;
  border-right: 16px solid black;
  border-left: 16px solid black;
  border-top: 20px solid black;
  border-bottom: 20px solid black;
  border-radius: 26px;
}
@media screen and (max-width: 800px) {
}
</style>
