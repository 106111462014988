<template>
  <div class="container" @mousemove="mouseMove">
    <div class="inner">
      <div class="content">
        <div class="icons-mobile">
          <div class="mobile-icon sophie"></div>
          <div class="mobile-icon sophie-hearteyes"></div>
          <div class="mobile-icon rob"></div>
          <div class="mobile-icon rob-yinyang"></div>
          <div class="mobile-icon jay"></div>
          <div class="mobile-icon jay-daisy"></div>
          <div class="mobile-icon sophie-cry"></div>
          <div class="mobile-icon star">
            <svg-icon icoType="star"></svg-icon>
          </div>
          <div class="mobile-icon sophie-lightning"></div>
          <div class="mobile-icon wideeyes"></div>
          <div class="mobile-icon star-2">
            <svg-icon icoType="star"></svg-icon>
          </div>
          <div class="mobile-icon star-3">
            <svg-icon icoType="star"></svg-icon>
          </div>
          <div class="mobile-icon star-4">
            <svg-icon icoType="star"></svg-icon>
          </div>
          <div class="mobile-icon star-rob">
            <svg-icon icoType="star"></svg-icon>
          </div>
        </div>
        <div class="parallax-icons-footer">
          <!-- item -->
          <div
            class="parallax-item item item-1"
            data-speed-x="-80"
            data-speed-y="-80"
          >
            <div class="inner-item sophie"></div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-2"
            data-speed-x="-80"
            data-speed-y="-80"
          >
            <div class="inner-item">
              <svg-icon icoType="yinyang"></svg-icon>
            </div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-3"
            data-speed-x="-60"
            data-speed-y="-60"
          >
            <div class="inner-item">
              <svg-icon icoType="hearteyes"></svg-icon>
            </div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-4"
            data-speed-x="-60"
            data-speed-y="-60"
          >
            <div class="inner-item jay"></div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-5"
            data-speed-x="-80"
            data-speed-y="-80"
          >
            <div class="inner-item rob"></div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-6"
            data-speed-x="-90"
            data-speed-y="-90"
          >
            <div class="inner-item">
              <svg-icon icoType="star"></svg-icon>
            </div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-7"
            data-speed-x="-60"
            data-speed-y="-60"
          >
            <div class="inner-item">
              <svg-icon icoType="lightning"></svg-icon>
            </div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-8"
            data-speed-x="-80"
            data-speed-y="-80"
          >
            <div class="inner-item">
              <svg-icon icoType="daisy"></svg-icon>
            </div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-9"
            data-speed-x="-50"
            data-speed-y="-50"
          >
            <div class="inner-item sophie-cry"></div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-10"
            data-speed-x="-60"
            data-speed-y="-60"
          >
            <div class="inner-item">
              <svg-icon icoType="star"></svg-icon>
            </div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-11"
            data-speed-x="-60"
            data-speed-y="-60"
          >
            <div class="inner-item">
              <svg-icon icoType="star"></svg-icon>
            </div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-12"
            data-speed-x="-60"
            data-speed-y="-60"
          >
            <div class="inner-item">
              <svg-icon icoType="strawberry"></svg-icon>
            </div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-13"
            data-speed-x="-80"
            data-speed-y="-80"
          >
            <div class="inner-item check-you-out"></div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-14"
            data-speed-x="-60"
            data-speed-y="-60"
          >
            <div class="inner-item">
              <svg-icon icoType="star"></svg-icon>
            </div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-15"
            data-speed-x="-60"
            data-speed-y="-60"
          >
            <div class="inner-item">
              <svg-icon icoType="star"></svg-icon>
            </div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-16"
            data-speed-x="-60"
            data-speed-y="-60"
          >
            <div class="inner-item">
              <svg-icon icoType="star"></svg-icon>
            </div>
          </div>
          <!-- item -->
          <div
            class="parallax-item item item-17"
            data-speed-x="-60"
            data-speed-y="-60"
          >
            <div class="inner-item">
              <svg-icon icoType="star"></svg-icon>
            </div>
          </div>
        </div>
        <div class="inner-content">
          <h1>Download the app. <br />Make the story your own.</h1>
          <div class="qr-code"><QRCode qrclass="prefooter" /></div>
          <mobile-links></mobile-links>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
function doParallaxEffect(event) {
  const parallaxContainer = document.querySelector(".parallax-icons-footer");
  const parallaxItems = document.querySelectorAll(".parallax-item");
  const fixer = 0.0004;
  const pageX =
    event.pageX - parallaxContainer.getBoundingClientRect().width * 0.5;
  const pageY =
    event.pageY - parallaxContainer.getBoundingClientRect().height * 0.5;
  for (let i = 0; i < parallaxItems.length; i++) {
    let item = parallaxItems[i];
    let speedX = item.getAttribute("data-speed-x");
    let speedY = item.getAttribute("data-speed-y");
    gsap.to(item, {
      x: (item.offsetLeft + pageX * speedX) * fixer,
      y: (item.offsetTop + pageY * speedY) * fixer,
    });
  }
}

import gsap from "gsap";
import QRCode from "./svg/QRCode.vue";
import MobileLinks from "../components/MobileLinks.vue";
import SvgIcon from "../components/svg/SvgIcon.vue";

export default {
  name: "PreFooter",
  components: { QRCode, MobileLinks, SvgIcon },
  methods: {
    mouseMove(event) {
      doParallaxEffect(event);
    },
  },
};
</script>

<style scoped>
.container {
  height: 1000px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #ebfaf2 0%,
    rgba(235, 250, 242, 0) 100%
  );
}
.content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.inner-content {
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inner {
  height: 100%;
  position: relative;
}

.parallax-icons-footer {
  z-index: 0;
  pointer-events: none;
  position: absolute;
  width: 90%;
  height: 80%;
  top: 50px;
}

.icons-mobile {
  display: none;
  z-index: 0;
  pointer-events: none;
  position: absolute;
  width: 90%;
  height: 80%;
  top: 100px;
}

.mobile-icon {
  position: absolute;
  animation: animatedBackgroundMobile 2s ease-in-out infinite;
}

@keyframes animatedBackgroundMobile {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  50% {
    transform: translate(0px, 10px) scale(1.1) rotate(10deg);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

.mobile-icon.sophie {
  width: 265px;
  height: 265px;
  top: -60px;
  left: -60px;
  background: url("../assets/images/stickers/sticker-sophiameet.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.mobile-icon.rob {
  width: 189px;
  height: 176px;
  top: 0px;
  right: -60px;
  background: url("../assets/images/stickers/animated/sticker-robglasses.gif");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.mobile-icon.jay {
  width: 185px;
  height: 153px;
  bottom: 50px;
  left: -90px;
  background: url("../assets/images/stickers/animated/sticker-jayhead.gif");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.mobile-icon.sophie-cry {
  width: 131px;
  height: 131px;
  bottom: 50px;
  right: -60px;
  background: url("../assets/images/stickers/animated/sticker-sophiateardrops.gif");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.mobile-icon.sophie-lightning {
  width: 50px;
  height: 50px;
  bottom: 80px;
  right: 25px;
  transform: rotate(10deg);
  background: url("../assets/images/stickers/lightning.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.mobile-icon.sophie-hearteyes {
  width: 50px;
  height: 50px;
  top: 80px;
  left: 100px;
  transform: rotate(10deg);
  background: url("../assets/images/stickers/hearteyes.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.mobile-icon.rob-yinyang {
  width: 50px;
  height: 50px;
  top: 155px;
  right: 0px;
  transform: scale(0.9);
  background: url("../assets/images/stickers/yinyang.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.mobile-icon.jay-daisy {
  width: 50px;
  height: 50px;
  bottom: 160px;
  left: 30px;
  background: url("../assets/images/stickers/daisy.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.mobile-icon.star {
  top: -40px;
  left: 180px;
  transform: scale(2);
}

.mobile-icon.wideeyes {
  width: 150px;
  height: 150px;
  bottom: -40px;
  left: 30%;
  background: url("../assets/images/stickers/animated/sticker-checkyouout.gif");
  background-size: cover;
  background-repeat: no-repeat;
  overflow: visible;
  transform: rotate(50deg);
}

.mobile-icon.star-2 {
  bottom: -60px;
  right: 30px;
  transform: scale(0.8) rotate(40deg);
}
.mobile-icon.star-3 {
  bottom: 80px;
  right: 220px;
  transform: scale(2) rotate(40deg);
}
.mobile-icon.star-4 {
  bottom: -20px;
  left: 80px;
  transform: scale(1) rotate(40deg);
}

.mobile-icon.star-rob {
  top: 80px;
  right: 40px;
  transform: scale(1);
}

.parallax-item {
  position: absolute;
}

.inner-item {
  animation: animatedBackground 3s ease-in-out infinite;
}

.inner-item.sophie {
  width: 309px;
  height: 309px;
  background: url("../assets/images/stickers/sticker-sophiameet.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.inner-item.jay {
  width: 143px;
  height: 208px;
  background: url("../assets/images/stickers/animated/sticker-jayhead.gif");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.inner-item.rob {
  width: 298px;
  height: 278px;
  background: url("../assets/images/stickers/animated/sticker-robglasses.gif");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.inner-item.sophie-cry {
  width: 285px;
  height: 285px;
  background: url("../assets/images/stickers/animated/sticker-sophiateardrops.gif");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.inner-item.check-you-out {
  width: 285px;
  height: 285px;
  background: url("../assets/images/stickers/animated/sticker-checkyouout.gif");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* aimee top left */
.item-1 {
  top: 50px;
  left: 10%;
}

/* yinyang */
.item-2 {
  top: 300px;
  right: 10%;
  z-index: 1;
  transform: scale(0.6);
}

/* smile eyes */
.item-3 {
  top: 200px;
  left: 22%;
  transform: scale(1.2);
}

/* jay head */
.item-4 {
  top: 600px;
  left: 0%;
}

/* rob */
.item-5 {
  top: 80px;
  right: 10%;
}

/* star top left (right of rob) */
.item-6 {
  top: 230px;
  right: 0px;
}

/* lightning */
.item-7 {
  top: 500px;
  right: 10%;
  transform: scale(1.1) rotate(-15deg);
}

/* jay daisy */
.item-8 {
  top: 600px;
  left: 5%;
}

/* aimee cry */
.item-9 {
  top: 520px;
  right: 15%;
  transform: scale(0.9);
}

/* star top left */
.item-10 {
  top: 150px;
  left: 20px;
  transform: scale(0.9);
}

/* star top left of rob */
.item-11 {
  top: 50px;
  right: 320px;
  transform: scale(0.6);
}

/* strawberry */
.item-12 {
  top: 250px;
  left: 50%;
  transform: scale(1.2) rotate(15deg);
}

/* check you out */
.item-13 {
  bottom: -25%;
  left: 20%;
  transform: scale(0.8) rotate(20deg);
}

/* star left of qr code */
.item-14 {
  bottom: 80px;
  left: 30%;
  transform: scale(0.8);
}

/* star below jay head */
.item-15 {
  bottom: -150px;
  left: 5%;
  transform: scale(0.5);
}

/* star next to lightning */
.item-16 {
  top: 520px;
  right: 30px;
  transform: scale(0.8);
}

/* star bottom right */
.item-17 {
  top: 720px;
  right: 100px;
  transform: scale(0.5);
}

@keyframes animatedBackground {
  0% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  50% {
    transform: translate(0px, 20px) scale(1.05);
  }
  100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
}

h1 {
  font-size: 80px;
  letter-spacing: -4%;
  text-align: center;
  margin-bottom: 80px;
  color: #000;
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .parallax-icons-footer {
    display: none;
  }
  .icons-mobile {
    display: block;
  }
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .parallax-icons-footer {
    display: none;
  }
  .icons-mobile {
    display: block;
  }
}

/* Tablet and Phone */
@media (hover: none) {
  .parallax-icons-footer {
    display: none;
  }
  .icons-mobile {
    display: block;
  }
}

@media screen and (max-width: 900px) {
  h1 {
    font-size: 50px;
    margin-bottom: 40px;
  }
  .parallax-icons-footer {
    display: none;
  }
  .icons-mobile {
    display: block;
  }
}

@media screen and (max-width: 768px) {
  .inner-content {
    height: 200px;
  }
}

@media screen and (max-width: 500px) {
  h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .container {
    height: 800px;
  }
  .inner-content {
    height: 200px;
  }
  .qr-code {
    display: none;
  }
}

/* Tablet and Phone */
@media (hover: none) {
  .qr-code {
    display: none !important;
  }
  .inner-content {
    height: 200px;
  }
}
</style>
