<template>
  <div
    class="container"
    :class="{ show: showControls === true, hide: showControls === false }"
  >
    <div class="buttons">
      <transition name="video-buttons" mode="out-in">
        <div
          class="play-button"
          @click="playVid"
          v-if="!vidPlaying || this.percentPlayed * 100 === 100"
        >
          <play-button></play-button>
        </div>
        <div class="pause-button" @click="pauseVid" v-else>
          <pause-button></pause-button>
        </div>
      </transition>
    </div>
    <div id="progress">
      <transition name="volume-buttons" mode="out-in">
        <div id="volume" @click="volumeOff" v-if="volumeOn">
          <volume-on-icon></volume-on-icon>
        </div>
        <div id="volumeOff" @click="volumeUp" v-else>
          <volume-off-icon></volume-off-icon>
        </div>
      </transition>
      <div id="progressBar">
        <div id="progressInner" :style="progressBarWidth"></div>
      </div>
      <div id="fullscreen" @click="fullScreen"><full-screen></full-screen></div>
      <div id="timestamp">{{ timeStamp }}</div>
    </div>
  </div>
</template>

<script>
import PlayButton from "./svg/PlayButton.vue";
import PauseButton from "./svg/PauseButton.vue";
import FullScreen from "./svg/FullScreen.vue";
import VolumeOnIcon from "./svg/VolumeOnIcon.vue";
import VolumeOffIcon from "./svg/VolumeOffIcon.vue";

export default {
  data() {
    return {
      vidPlaying: false,
      volumeOn: true,
    };
  },
  watch: {
    showControls() {},
  },
  emits: [
    "play-started",
    "play-stopped",
    "fullscreen-requested",
    "mute-video",
    "unmute-video",
  ],
  name: "VideoControls",
  components: {
    PlayButton,
    PauseButton,
    FullScreen,
    VolumeOnIcon,
    VolumeOffIcon,
  },
  props: {
    percentPlayed: Number,
    timeStamp: String,
    showControls: Boolean,
  },
  methods: {
    playVid() {
      this.vidPlaying = true;
      this.$emit("play-started");
    },
    pauseVid() {
      this.vidPlaying = false;
      this.$emit("play-paused");
    },
    fullScreen() {
      this.$emit("fullscreen-requested");
    },
    volumeOff() {
      this.$emit("mute-video");
      this.volumeOn = false;
    },
    volumeUp() {
      this.$emit("unmute-video");
      this.volumeOn = true;
    },
  },
  computed: {
    progressBarWidth() {
      return { width: this.percentPlayed * 100 + "%" };
    },
  },
};
</script>
<style scoped>
.container {
  width: 100%;
  position: absolute;
  bottom: 20px;
  z-index: 1000;
  transition: all 0.5s ease-in-out;
}

.container.show {
  opacity: 1;
}

.container.hide {
  opacity: 0;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

#progress {
  margin: 0 auto;
  width: 95%;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  position: relative;
}

#progressBar {
  position: relative;
  background: rgba(0, 0, 0, 0.25);
  width: 85%;
  height: 5px;
}

#progressInner {
  position: absolute;
  left: 0px;
  top: 0px;
  background: #ffffff;
  height: 5px;
  transition: all 0.3s ease-in;
}

#fullscreen,
#volume,
#volumeOff {
  align-self: center;
  transition: all 0.2s ease-in-out;
}

#fullscreen:hover,
#volume:hover,
#volumeOff:hover {
  transform: scale(1.1);
  cursor: url("../assets/images/ui/mousehover.png"), auto;
}

#timestamp {
  color: white;
  font-size: 13px;
}

.video-buttons-enter-from,
.volume-buttons-enter-from {
  opacity: 0;
}

.video-buttons-enter-active,
.volume-buttons-enter-active {
  transition: all 0.5s ease-out;
}

.video-buttons-enter-to,
.volume-buttons-enter-to {
  opacity: 1;
}

.video-buttons-leave-from,
.volume-buttons-leave-from {
  opacity: 1;
}

.video-buttons-leave-active,
.volume-buttons-leave-active {
  transition: all 0.5s ease-in;
}

.video-buttons-leave-to,
.volume-buttons-leave-to {
  opacity: 0;
}

@media screen and (max-width: 600px) {
  #progress {
    height: 25px;
  }
  #progressBar {
    width: 50%;
  }
  .buttons {
    height: 50px;
  }
  .play-button,
  .pause-button {
    transform: scale(0.7);
    transition: none;
  }
  .play-button:active,
  .pause-button:active {
    transform: scale(0.7);
    transition: none;
  }
  .play-button:hover,
  .pause-button:hover {
    transform: scale(0.7);
    transition: none;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  #progress {
    height: 25px;
  }
  #progressBar {
    width: 50%;
  }
  .buttons {
    height: 50px;
  }
  .play-button,
  .pause-button {
    transform: scale(0.7);
    transition: none;
  }
  .play-button:active,
  .pause-button:active {
    transform: scale(0.7);
    transition: none;
  }
  .play-button:hover,
  .pause-button:hover {
    transform: scale(0.7);
    transition: none;
  }
}
</style>
