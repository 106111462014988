<template>
  <div class="container" style="overflow: hidden">
    <div class="headline">
      <section-headline
        headLine="Synopsis"
        className="synopsis"
      ></section-headline>
    </div>
    <p class="icon-para">
      Mr. Right<svg-icon icoType="daisy"></svg-icon>is an interactive
      <svg-icon icoType="blank"></svg-icon>romantic comedy, where<svg-icon
        icoType="blank"
      ></svg-icon>
      we meet<svg-icon icoType="smile"></svg-icon>Sophia just as she’s
      received<svg-icon icoType="blank"></svg-icon> an invitation to<svg-icon
        icoType="dice"
      ></svg-icon
      >her ex-boyfriend’s <svg-icon icoType="blank"></svg-icon>wedding.<svg-icon
        icoType="lightning"
      ></svg-icon
      >Still heartbroken,<svg-icon icoType="blank"></svg-icon> she
      attempts<svg-icon icoType="blank"></svg-icon> to win him back<svg-icon
        icoType="robot"
      ></svg-icon
      >by passing off a newly <svg-icon icoType="hearteyes"></svg-icon>developed
      robot<svg-icon icoType="blank"></svg-icon> (yes, really!)<svg-icon
        icoType="blank"
      ></svg-icon>
      as her date. Take<svg-icon icoType="strawberry"></svg-icon
      >control<svg-icon icoType="blank"></svg-icon> of her story in
      <svg-icon icoType="blank"></svg-icon>this mobile-first,<svg-icon
        icoType="blank"
      ></svg-icon>
      player-choice-driven<svg-icon icoType="yinyang"></svg-icon>experience
      that<svg-icon icoType="bitheart"></svg-icon>can result in
      <svg-icon icoType="blank"></svg-icon>one of seventeen<svg-icon
        icoType="butterfly"
      ></svg-icon
      >different <svg-icon icoType="blank"></svg-icon>endings<svg-icon
        icoType="blank"
      ></svg-icon
      >... so choose <svg-icon icoType="blank"></svg-icon> wisely!
    </p>
  </div>
</template>

<script>
function textEffect() {
  let heightAdjust;
  window.innerWidth > 900 ? (heightAdjust = 0) : (heightAdjust = 32);
  const paragraphBox = document.querySelector(".icon-para");
  let distToTop = paragraphBox.getBoundingClientRect().top - 200;
  if (distToTop > 5) {
    gsap.to(paragraphBox, {
      css: {
        lineHeight: distToTop / 8 + heightAdjust + "px",
        marginTop: distToTop / 4 + "px",
      },
      duration: 1,
      ease: "power2",
    });
  }
}

import gsap from "gsap";
import SvgIcon from "./svg/SvgIcon.vue";
import SectionHeadline from "./UI/SectionHeadline.vue";

export default {
  name: "IconTextArea",
  components: { SvgIcon, SectionHeadline },
  mounted() {
    document.addEventListener("scroll", function (event) {
      textEffect(event);
    });
  },
  unmounted() {
    document.removeEventListener("scroll");
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 95%;
  padding-top: 80px;
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  background: linear-gradient(
    179.96deg,
    #ffffff 42.68%,
    rgba(255, 255, 255, 0) 92.53%
  );

  max-width: 1400px;
  margin: 120px auto 150px;
}
.container p {
  position: relative;
  margin: 0;
  width: 80%;
  padding: 5% 0%;
  font-weight: 400;
  font-size: 40px;
  line-height: 0px;
  text-align: center;
  letter-spacing: -0.04em;
  color: #000000;
}

p span {
  display: inline-block;
}

@media screen and (max-width: 1024px) {
  .container {
    max-width: 98%;
    padding-top: 20px;
    border-top-left-radius: 27px;
    border-top-right-radius: 27px;
  }
  .container p {
    margin-top: 20px;
    font-size: 32px;
    line-height: 32px;
  }
  p span {
    padding: 0;
    margin: 0;
    width: 50px;
    height: 50px;
  }
  p span.blank {
    padding: 0;
    margin: 0;
    width: 0px;
    height: 50px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    margin: 80px auto 100px;
    width: 95%;
  }
}

@media screen and (max-width: 900px) and (orientation: landscape) {
  .container {
    max-width: 95%;
    padding-top: 20px;
    border-top-left-radius: 27px;
    border-top-right-radius: 27px;
  }
  .container p {
    width: 95%;
    margin-top: 20px;
    font-size: 36px;
    line-height: 36px;
  }
  p span {
    padding: 0;
    margin: 0;
    width: 50px;
    height: 50px;
  }
  p span.blank {
    padding: 0;
    margin: 0;
    width: 1px;
    height: 50px;
  }
}
</style>
